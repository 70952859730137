// import libs
import { all } from 'redux-saga/effects';
import {
  getRoomsSaga,
  getListPlayersSaga,
  getGameInfoSaga,
  addPlayerToHistorySaga,
  getListHistoryPlayerSaga,
  updatePlayerHistorySaga,
  getPrizesGameSaga,
  getRoomByIdSaga,
  chargeBetaSaga,
  saveMyTeamHistorySaga,
  saveAnalyticsSaga,
  requestShareSaga,
  updateMaintainCheckedSaga
} from 'modules/home/sagas';

import {
  signInSaga,
  signUpSaga,
  resetPasswordSaga,
  changePasswordSaga,
  verifyPhoneCodeSaga,
  logoutSaga,
  getMypageSaga,
  getWithDrawSaga,
  getPrizeSaga,
  getBankAccountSaga,
  saveBankAccountSaga,
  getCurrentUserSaga,
  updateProfileSaga,
  updatePasswordSaga,
  promotionCodeSaga,
  withDrawMoneySaga,
  getEarnedSaga,
  loginFacebookSaga,
  changeLanguageSaga
} from '../modules/account/sagas';

import {
  getDetailPlayerSaga,
  getPlayerSearchSaga,
  getPlayerNameSaga,
  getPlayerInfoSaga
} from '../modules/player/sagas';

import {
  postContactSaga,
  getContactSaga,
  getContactDetailSaga,
  answerContactSaga
} from '../modules/contact/sagas';

import { 
  paymentSaveSaga,
  paymentMasterCardSaga,
  chillPayPaymentSaga,
  paymentStatusSaga,
  getListPromotionSaga,
  updateRedMarkSaga
} from '../modules/payment/sagas';

import {
  getRankingTeamSaga,
  getRankingRoomSaga,
  getRankingMyteamSaga,
  getMyteamSaga,
  getResultFootballSaga,
  getPlayerScoreSaga,
  getRankingByUserIdSaga,
} from '../modules/ranking/sagas';

import { getNoticeListSaga, settingNoticeSaga } from '../modules/notices/sagas';

export default function* RootSaga() {
  yield all([
    signInSaga(),
    signUpSaga(),
    verifyPhoneCodeSaga(),
    getRoomsSaga(),
    getListPlayersSaga(),
    resetPasswordSaga(),
    getDetailPlayerSaga(),
    logoutSaga(),
    changePasswordSaga(),
    paymentSaveSaga(),
    getGameInfoSaga(),
    changePasswordSaga(),
    addPlayerToHistorySaga(),
    getListHistoryPlayerSaga(),
    updatePlayerHistorySaga(),
    getMypageSaga(),
    getWithDrawSaga(),
    getPrizeSaga(),
    getPlayerSearchSaga(),
    getPlayerNameSaga(),
    getPrizesGameSaga(),
    getRoomByIdSaga(),
    getBankAccountSaga(),
    saveBankAccountSaga(),
    getRankingTeamSaga(),
    getRankingRoomSaga(),
    getCurrentUserSaga(),
    updateProfileSaga(),
    updatePasswordSaga(),
    promotionCodeSaga(),
    updatePasswordSaga(),
    getRankingMyteamSaga(),
    getMyteamSaga(),
    getResultFootballSaga(),
    getPlayerScoreSaga(),
    getRankingByUserIdSaga(),
    withDrawMoneySaga(),
    getNoticeListSaga(),
    settingNoticeSaga(),
    getEarnedSaga(),
    chargeBetaSaga(),
    saveMyTeamHistorySaga(),
    loginFacebookSaga(),
    paymentMasterCardSaga(),
    saveAnalyticsSaga(),
    requestShareSaga(),
    changeLanguageSaga(),
    postContactSaga(),
    getContactSaga(),
    getContactDetailSaga(),
    answerContactSaga(),
    getPlayerInfoSaga(),
    chillPayPaymentSaga(),
    paymentStatusSaga(),
    updateMaintainCheckedSaga(),
    getListPromotionSaga(),
    updateRedMarkSaga()
  ]);
}
