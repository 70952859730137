const images = {
  icon_logo: require('../assets/logos/white.svg'),
  phone_icon: require('../assets/logos/Shape.png'),
  mail: require('../assets/logos/mail.svg'),
  password: require('../assets/logos/password.svg'),
  user: require('../assets/logos/user.svg'),
  promotion: require('../assets/logos/promotion.svg'),
  line_logo: require('../assets/logos/line.png'),
  menu_logo: require('../assets/logos/menu.png'),
  close_icon: require('../assets/logos/close.png'),
  game_top: require('../assets/logos/game-top.svg'),
  my_page: require('../assets/logos/mypage.svg'),
  term_icon: require('../assets/logos/terms.svg'),
  how_to_play: require('../assets/logos/howto.svg'),
  faq_logo: require('../assets/logos/faq.svg'),
  company_logo: require('../assets/logos/company.svg'),
  privacy_logo: require('../assets/logos/privacy.svg'),
  contact: require('../assets/logos/contact.svg'),
  news: require('../assets/logos/news.svg'),
  invite_member: require('../assets/logos/invite_member.svg'),
  invite_member_banner: require('../assets/howto/invite_member.png'),
  results: require('../assets/logos/result.svg'),
  logout: require('../assets/logos/logout.svg'),
  menu_bar: require('../assets/logos/menu.svg'),
  back_icon: require('../assets/logos/back.svg'),
  stadium: require('../assets/stadium/stadium.png'),
  player_0: require('../assets/uniform/team_0.png'),
  game_info: require('../assets/logos/game_info.svg'),
  clock: require('../assets/logos/clock.svg'),
  cup_gold: require('../assets/logos/gold.svg'),
  cup_silver: require('../assets/logos/silver.svg'),
  cup_bronze: require('../assets/logos/bronze.svg'),
  arrow_down: require('../assets/logos/Path.png'),
  uniform: require('../assets/logos/uniform.png'),
  search_icon: require('../assets/logos/search.png'),
  box_player: require('../assets/logos/box_player.svg'),
  visa_payment: require('../assets/logos/visa_payment.svg'),
  help_payment: require('../assets/logos/help_payment.svg'),
  notification_faild: require('../assets/logos/notification_faild.svg'),
  notification_success: require('../assets/logos/notification_success.svg'),
  notification_cancel: require('../assets/logos/notification_cancel.svg'),
  tick_faild: require('../assets/logos/tick_faild.svg'),
  tick_success: require('../assets/logos/tick_success.svg'),
  tick_cancel: require('../assets/logos/tick_cancel.svg'),
  nav_li: require('../assets/logos/nav_li.svg'),
  search_input: require('../assets/logos/search_input.svg'),
  close_input: require('../assets/logos/close_input.svg'),
  close_player: require('../assets/logos/close.svg'),
  mypage__down: require('../assets/logos/mypage__down.svg'),
  facebook_icon: require('../assets/logos/fb.png'),
  line_icon: require('../assets/logos/line_share.png'),
  profile_empty: require('../assets/logos/profile_empty.svg'),
  camera: require('../assets/logos/camera.svg'),
  arrow_right: require('../assets/logos/arrow_right.svg'),
  arrow_select: require('../assets/logos/arrow_select.svg'),
  icon_facebook: require('../assets/logos/icon_facebook.svg'),
  icon_line: require('../assets/logos/icon_line.svg'),
  bg_myrank: require('../assets/logos/bg_myrank.png'),
  bg_notice: require('../assets/logos/background_new.png'),
  mail_yellow: require('../assets/logos/mail_yellow.svg'),
  qr_code: require('../assets/logos/payment_qrcode.png'),
  payment_i: require('../assets/logos/payment_i.svg'),
  payment_phone: require('../assets/logos/payment_phone.svg'),
  payment_email: require('../assets/logos/payment_email.svg'),
  payment_check: require('../assets/logos/payment_check.svg'),
  payment_note: require('../assets/logos/payment_note.svg'),
  view_more: require('../assets/logos/view_more.svg'),
  view_detail: require('../assets/logos/view_detail.svg'),
  th_flag: require('../assets/logos/TH.svg'),
  jp_flag: require('../assets/logos/JP.svg'),
  en_flag: require('../assets/logos/GB.svg'),
  how_to_01: require('../assets/howto/how_to_01.svg'),
  how_to_02: require('../assets/howto/how_to_02.png'),
  how_to_03: require('../assets/howto/how_to_03.png'),
  how_to_04: require('../assets/howto/how_to_04.svg'),
  how_to_05: require('../assets/howto/how_to_05.svg'),
  how_to_06: require('../assets/howto/how_to_06.svg'),
  how_to_left: require('../assets/howto/how_to_left.svg'),
  how_to_right: require('../assets/howto/how_to_right.svg'),
  back_how_to_01: require('../assets/howto/back_howto_01.png'),
  back_how_to_02: require('../assets/howto/back_howto_02.png'),
  back_how_to_03: require('../assets/howto/back_howto_03.png'),
  thumbnail_thai: require('../assets/howto/ogp_TH.png'),
  thumbnail_japan: require('../assets/howto/ogp_JP.png'),
  right_line: require('../assets/logos/right_line.svg'),
  top_share_ja: require('../assets/logos/top_share_ja.png'),
  top_share_th: require('../assets/logos/top_share_th.png'),
  worldcup_ja: require('../assets/logos/worldcup_ja.png'),
  worldcup_th: require('../assets/logos/worldcup_th.png'),
  facebook_login: require('../assets/logos/facebook_logo.png'),
  sfv_test: require('../assets/logos/qrcode_test.svg'),
  loading: require('../assets/howto/eclipse.svg'),
  email: require('../assets/howto/email.svg'),
  bangkok_bank: require('../assets/howto/bangkok_bank.jpeg'),
  icon_warning: require('../assets/howto/error.svg'),
  tick: require('../assets/logos/tick.svg'),
  user_icon: require('../assets/logos/user_icon.png')
};

export default images;
