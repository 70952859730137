// @flow
import React, { memo } from 'react';
import Line from 'commons/components/Line';
import images from 'themes/images';
import { LineLogin } from 'reactjs-line-login';
import { useTranslation } from 'react-i18next';

type Props = {
  children: any,
  title: string,
  titleClassColor: string,
  isShowBtnLoginLine?: boolean,
  isShowLine?: boolean,
};

const Template = ({
  children,
  title,
  titleClassColor,
  isShowBtnLoginLine = true,
  isShowLine = true,
}: Props) => {
  const { t } = useTranslation();
  const lg = useTranslation();

  return (
    <div className={`account-page lang-${lg.i18n.language}`}>
      <div className="account-page__wrapper">
        <div className="account-page__title">
          <div className="account-page__title__logo">
            <img src={images.icon_logo.default} alt="logo" />
          </div>
          <p className={`account-page__title__text ${titleClassColor}`}>
            {title}
          </p>
          {isShowBtnLoginLine && (
            <div className="account-page__login-line">
              <LineLogin
                clientID="1656516504"
                clientSecret="3487aae03bc4aaff3728edee862828c2"
                state=""
                nonce=""
                redirectURI="https://ad55-2402-800-6205-4d5d-160-c73a-5735-c2c2.ngrok.io/login"
                scope=""
              />
              <div className="account-page__login-line__content">
                <img src={images.line_logo.default} alt="line-logo" />
                <span>{t('lineLogin')}</span>
              </div>
            </div>
          )}
        </div>
        {isShowLine && <Line text="または" customClass="custom-link" />}
        {children}
      </div>
    </div>
  );
};

Template.defaultProps = {
  isShowBtnLoginLine: true,
  isShowLine: true,
};

export default memo<Props>(Template);
