import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
// import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'reactjs-line-login/dist/index.css';
import './App.scss';
import { Provider } from 'react-redux';
import moment  from 'moment';
import Router from 'routers';
import createStore from './stores/createStore';

function App() {
  const { store, persistor } = createStore();

  const maintance_time = localStorage.getItem('maintance');
  if(maintance_time && moment(maintance_time).utcOffset('+07:00').valueOf() > moment().utcOffset('+07:00').valueOf() && window.location.pathname !== '/maintance-page') {
    window.location.href = '/maintance-page';
  }

  return (
    <div className="App">
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Router />
        </Provider>
      </PersistGate>
    </div>
  );
}

export default App;
