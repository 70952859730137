/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

/**
 * worker Saga: will be fired on getRankingTeam actions
 *
 * @param {*} action
 */
function* getRankingTeam(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.HISTORY_PLAYERS(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'rankings/rankingTeamRequestSuccess', data });
    } else {
      yield put({
        type: 'rankings/rankingTeamRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/rankingTeamRequestFailed' });
  }
}

/**
 * Dispatched `getRankingTeamSaga` action.
 */
function* getRankingTeamSaga() {
  yield takeLatest('rankings/rankingTeamRequest', getRankingTeam);
}

/**
 * worker Saga: will be fired on getRankingRoom actions
 *
 * @param {*} action
 */
function* getRankingRoom(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.ROOM_RANKING(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'rankings/rankingRoomRequestSuccess', data });
    } else {
      yield put({
        type: 'rankings/rankingRoomRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/rankingRoomRequestFailed' });
  }
}

/**
 * Dispatched `getRankingRoomSaga` action.
 */
function* getRankingRoomSaga() {
  yield takeLatest('rankings/rankingRoomRequest', getRankingRoom);
}

/**
 * worker Saga: will be fired on getRankingRoom actions
 *
 * @param {*} action
 */
function* getResultFootball(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.RESULT_FOOTBALL, {
        competition_name: action?.payload?.competition_name,
      })
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'rankings/getResultFootballSuccess', data });
    } else {
      yield put({
        type: 'rankings/getResultFootballFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/getResultFootballFailed' });
  }
}

/**
 * Dispatched `getResultFootballSaga` action.
 */
function* getResultFootballSaga() {
  yield takeLatest('rankings/getResultFootball', getResultFootball);
}

/**
 * worker Saga: will be fired on getMyteam actions
 *
 * @param {*} action
 */
function* getMyteam(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_MY_TEAM(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;
      yield put({ type: 'rankings/myTeamRequestSuccess', data });
    } else {
      yield put({
        type: 'rankings/myTeamRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/myTeamRequestFailed' });
  }
}

/**
 * Dispatched `getMyteamSaga` action.
 */
function* getMyteamSaga() {
  yield takeLatest('rankings/myTeamRequest', getMyteam);
}

/**
 * worker Saga: will be fired on getRankingMyteam actions
 *
 * @param {*} action
 */
function* getRankingMyteam(action) {
  try {
    const { gameId, tab, teamId } = action?.payload;
    const response = yield call(() =>
      API.get(ROUTES.GET_LIST_TOP(gameId), {
        position: tab,
        team_id: teamId
      })
    );

    if (response.ok) {
      const { data } = response;
      yield put({ type: 'rankings/rankingDetailRequestSuccess', data });
    } else {
      yield put({
        type: 'rankings/rankingDetailRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/rankingDetailRequestFailed' });
  }
}

/**
 * Dispatched `getRankingMyteamSaga` action.
 */
function* getRankingMyteamSaga() {
  yield takeLatest('rankings/rankingDetailRequest', getRankingMyteam);
}

/**
 * worker Saga: will be fired on getPlayerScore actions
 *
 * @param {*} action
 */
function* getPlayerScore(action) {
  try {
    const { gameId, memberId } = action?.payload;
    const response = yield call(() =>
      API.get(ROUTES.GET_SCORE_PLAYER(gameId, memberId))
    );

    if (response.ok) {
      const { data } = response.data;
      yield put({ type: 'rankings/getPlayerScoreRequestSuccess', data });
    } else {
      yield put({
        type: 'rankings/getPlayerScoreRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/getPlayerScoreRequestFailed' });
  }
}

/**
 * Dispatched `getPlayerScore` action.
 */
function* getPlayerScoreSaga() {
  yield takeLatest('rankings/getPlayerScoreRequest', getPlayerScore);
}

function* getRankingByUserId(action) {
  try {
    const response = yield call(() =>
      API.get(
        ROUTES.USER_RANKING(action?.payload?.roomId, action?.payload?.userId)
      )
    );

    if (response.ok) {
      const { data } = response.data;
      yield put({ type: 'rankings/getRankingByUserIdSuccess', data });
    } else {
      yield put({
        type: 'rankings/getRankingByUserIdFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'rankings/getRankingByUserIdFailed' });
  }
}

/**
 * Dispatched `getMyteamSaga` action.
 */
function* getRankingByUserIdSaga() {
  yield takeLatest('rankings/getRankingByUserId', getRankingByUserId);
}

export {
  getRankingTeamSaga,
  getRankingRoomSaga,
  getResultFootballSaga,
  getRankingMyteamSaga,
  getMyteamSaga,
  getPlayerScoreSaga,
  getRankingByUserIdSaga,
};
