import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contacts: [],
  detail_contacts: [],
  isProcessing: false,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    contactStoreRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    contactStoreRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    contactStoreRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    getContactRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getContactRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.contacts = action.data;
    },

    getContactRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    getContactDetailRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getContactDetailRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.detail_contacts = action.data;
    },

    getContactDetailRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    answerContactRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    answerContactRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.contacts = action.data;
    },

    answerContactRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    resetData: (state) => {
      state.errorsRequest = [];
      state.type = '';
    },
  },
});

const { actions, reducer } = contactSlice;

export const {
  contactStoreRequest,
  getContactRequest,
  getContactDetailRequest,
  answerContactRequest,
  resetData,
} = actions;

export default reducer;
