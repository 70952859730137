/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
function* postContact(action) {
  try { 
    const response = yield call(() =>
      API.post(ROUTES.CONTACT_FORM, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'contact/contactStoreRequestSuccess', data });
    } else { 
      yield put({
        type: 'contact/contactStoreRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'contact/contactStoreRequestFailed' });
  }
}

/**
 * Dispatched `postContactSaga` action.
 */
function* postContactSaga() {
  yield takeLatest('contact/contactStoreRequest', postContact);
}

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
 function* getContact(action) {
  try { 
    const response = yield call(() =>
      API.get(ROUTES.CONTACT_FORM, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'contact/getContactRequestSuccess', data });
    } else { 
      yield put({
        type: 'contact/getContactRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'contact/getContactRequestFailed' });
  }
}

/**
 * Dispatched `getContactSaga` action.
 */
function* getContactSaga() {
  yield takeLatest('contact/getContactRequest', getContact);
}

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
 function* getContactDetail(action) {
  try { 
    const response = yield call(() =>
      API.get(ROUTES.CONTACT_DETAIL(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;
      yield put({ type: 'contact/getContactDetailRequestSuccess', data });
    } else { 
      yield put({
        type: 'contact/getContactDetailRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'contact/getContactDetailRequestFailed' });
  }
}

/**
 * Dispatched `getContactDetailSaga` action.
 */
function* getContactDetailSaga() {
  yield takeLatest('contact/getContactDetailRequest', getContactDetail);
}

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
 function* answerContact(action) {
  try { 
    const response = yield call(() =>
      API.post(ROUTES.CONTACT_DETAIL(action?.payload?.id), {message: action?.payload?.message})
    );

    if (response.ok) {
      const { data } = response.data;
      console.log(data, 'data');
      yield put({ type: 'contact/answerContactRequestSuccess', data });
    } else { 
      yield put({
        type: 'contact/answerContactRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'contact/answerContactRequestFailed' });
  }
}

/**
 * Dispatched `answerContactSaga` action.
 */
function* answerContactSaga() {
  yield takeLatest('contact/answerContactRequest', answerContact);
}

export {
  postContactSaga,
  getContactSaga,
  getContactDetailSaga,
  answerContactSaga
};
