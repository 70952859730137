// @flow
import React from 'react';

type Props = { text: string, customClass: string };

const Line = ({ text, customClass }: Props) => (
  <div className={`line-wrapper ${customClass}`}>
    <span className="line" />
    <span className="line" />
    <p className="line-text">{text}</p>
  </div>
);

export default Line;
