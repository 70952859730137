// @flow
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  isShowModal: boolean,
  title: string,
  textBtnCancel?: string,
  handleClose?: Function,
  textBtnSubmit?: string,
  handleSubmit?: Function,
  children: any,
  iconClose?: Boolean,
  isDisabled?: Boolean,
  customClass?: string,
};

const ModalConfirm = ({
  isShowModal,
  children,
  title,
  textBtnCancel = '',
  handleClose = () => {},
  textBtnSubmit = '',
  handleSubmit = () => {},
  iconClose = false,
  isDisabled = false,
  customClass = ''
}: Props) => (
  <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={isShowModal}
    contentClassName={customClass}
  >
    {
      title !== '' && 
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{title}</h2>
            { iconClose &&
              ( <span className={`close-header ${isDisabled ? 'close-disabled' : ''}`} onClick={handleClose}>&times;</span> ) 
            }
          </Modal.Title>
        </Modal.Header>
    }
    <Modal.Body>{children}</Modal.Body>
    {
      !iconClose && (
        <Modal.Footer>
          {textBtnCancel && (
            <button className="btn-modal-confirm btn-modal-cancel" onClick={handleClose}>
              {textBtnCancel}
            </button>
          )}

          {textBtnSubmit && (
            <button className="btn-modal-confirm" onClick={handleSubmit}>
              {textBtnSubmit}
            </button>
          )}
        </Modal.Footer>
      )
    }
  </Modal>
);

ModalConfirm.defaultProps = {
  handleClose: () => {},
  handleSubmit: () => {},
  textBtnSubmit: '',
  textBtnCancel: '',
  iconClose: false,
  isDisabled: false,
  customClass: ''
};

export default React.memo<Props>(ModalConfirm);
