// @flow
import React, { memo } from 'react';

type Props = {
  placeholder?: string,
  value?: string | number,
  errorMsg?: string,
  innerRef?: { current: HTMLInputElement | null },
  label?: string,
  disabled?: boolean,
  icon?: string,
  type?: string,
  onBlur?: Function,
  onChange?: Function,
  customInput?: string,
  customClassWrap?: string,
  name?: string,
  autoComplete: string,
  maxLength: string
};

export const Input = ({
  placeholder = '',
  value = '',
  errorMsg = '',
  innerRef = null,
  label = '',
  disabled = false,
  type = 'text',
  onBlur = null,
  onChange = null,
  customInput = '',
  customClassWrap = '',
  icon = '',
  name = '',
  autoComplete="",
  maxLength=""
}: Props) => (
  <div className={`input__wrapper ${customClassWrap || ''}`}>
    {!!label && <p className="input__label">{label}</p>}
    <div className="input__box">
      {icon && (
        <div className="input__box__icon">
          <img src={icon} alt="icon-input" />
        </div>
      )}
      <input
        className={`input ${customInput || ''}`}
        defaultValue={value}
        placeholder={placeholder}
        ref={innerRef}
        disabled={disabled}
        type={type}
        onBlur={onBlur}
        onChange={onChange}
        autoCapitalize="none"
        autoComplete={autoComplete}
        name={name}
        maxLength={maxLength}
      />
    </div>
    {errorMsg && <p className="error-msg">{errorMsg}</p>}
  </div>
);

Input.defaultProps = {
  placeholder: '',
  value: '',
  errorMsg: '',
  innerRef: null,
  label: '',
  disabled: false,
  icon: '',
  type: 'text',
  onBlur: null,
  onChange: null,
  customInput: '',
  customClassWrap: '',
  name: '',
};

export default memo<Props>(Input);
