import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playerInfo: {},
  isProcessing: false,
  errorsRequest: [],
  payloadSignup: {},
  playerSearch: [],
  playerName: []
};

const accountSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    detailPlayerRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    detailPlayerRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.playerInfo = action?.data;
    },

    detailPlayerRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    playerSearchRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    playerSearchRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.playerSearch = action?.data;
    },

    playerSearchRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    playerNameRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    playerNameRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.playerName = action?.data;
    },

    playerNameRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    playerInfoRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    playerInfoRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.playerName = action?.data;
    },

    playerInfoRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },
    
    resetData: (state) => {
      state.errorsRequest = [];
      state.type = '';
    },
  },
});

const { actions, reducer } = accountSlice;

export const {
  detailPlayerRequest,
  detailPlayerRequestSuccess,
  detailPlayerRequestFailed,
  playerSearchRequest,
  playerSearchRequestSuccess,
  playerSearchRequestFailed,
  playerNameRequest,
  playerInfoRequest,
  playerNameRequestSuccess,
  playerNameRequestFailed,
  resetData
} = actions;

export default reducer;
