import { combineReducers } from '@reduxjs/toolkit';
import accountReducer from 'modules/account/redux';
import homeReducer from 'modules/home/redux';
import playerReducer from 'modules/player/redux';
import paymentReducer from 'modules/payment/redux';
import rankingReducer from 'modules/ranking/redux';
import noticesReducer from 'modules/notices/redux';
import contactReducer from 'modules/contact/redux';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  account: accountReducer,
  home: homeReducer,
  player: playerReducer,
  payment: paymentReducer,
  ranking: rankingReducer,
  notices: noticesReducer,
  contact: contactReducer
});

const rootReducer = (state, action) => { 
  if (action.type === 'accounts/logoutRequestSuccess') {
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`);
    });
    /* eslint-disable no-param-reassign */
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;
