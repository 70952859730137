/* eslint-disable no-useless-escape */
const REGEX = {
  ACCESS_TOKEN: /access_token=((.)+)$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^[A-Za-z0-9]{6,30}$/,
  MAX_LENGTH: /^[A-Za-z0-9]{6,30}$/,
  USER_NAME:
    /^([a-zA-Z0-9][一-龠][ぁ-ゔ][ァ-ヴー][々〆〤]|[^\\!@#\\$%\\^\\&*\\)\\(+=._-]+$)/,
  NUMBER: /^[-]?(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
  // PHONE_NUMBER: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  PHONE_NUMBER: /^[+]*\w{1,15}$/,
  CVC_NUMBER: /^\d{3}$/,
  VISA_NUMBER: /^4[0-9]{12}(?:[0-9]{3})?$/,
  AMEX_NUMBER: /^3[47][0-9]{13}$/,
  MASTERCARD_NUMBER: /^5[1-5][0-9]{14}$/,
  SPECIAL: /^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?/,
};

export default REGEX;
