/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable  prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable radix */
// import moment from 'moment';
import moment from 'moment';
import _ from 'lodash';
import REGEX from '../constants/regex';
import ERROR_MESSAGE from '../constants/errorMsg';

const languageCode = window.localStorage.getItem('language_code');
export const languageDetectUrl = () => languageCode ? languageCode : 'th';
export const validator = (objectVal: Object, validate: Object) => {
  const error = {};

  const keys = Object.keys(objectVal);

  for (let i = 0, { length } = keys; i < length; i += 1) {
    const key = keys[i];
    const rules = validate[key];
    const value = objectVal[key];
    let errorMsg = '';

    for (let j = 0; j < rules.length; j += 1) {
      switch (rules[j]) {
        case 'required':
          if (value === '' || value === null || value === undefined) {
            errorMsg = 'validateRequired'
          }
          break;

        case 'phoneNumber':
          if (!REGEX.PHONE_NUMBER.test(value)) {
            errorMsg = 'validatePhoneNumber'
          }
          break;
        case 'email':
          if (!REGEX.EMAIL.test(value)) {
            errorMsg = 'validateEmail'
          }
          break;
        case 'username':
          if (
            !REGEX.USER_NAME.test(value) &&
            value &&
            !REGEX.SPECIAL.test(value)
          ) {
            errorMsg = 'validateUserName'
          }
          break;
        case 'maxLength':
          if (value.length > 20) {
            errorMsg = 'validateMaxlength'
          }
          break;
        case 'user_name_empty':
          if (value === '' || value === null || value === undefined) {
            errorMsg = 'validateUserNameEmpty'
          }

          break;
        case 'password':
          if (value && !REGEX.PASSWORD.test(value)) {
            errorMsg = 'validatePassword'
          }
          break;
        case 'minLength':
          if (value && value.length < 6) {
            errorMsg = 'validateMinLength'
          }
          break;
        case 'minLengthUser':
          if (value && value.length < 6) {
            errorMsg = 'validateMinLengthUser'
          }
          break;
        case 'confirmPassword':
          if (value !== objectVal.password) {
            errorMsg = 'validateConfirmPassword'
          }
          break;

        case 'regexCard':
          if (!REGEX.VISA_NUMBER.test(value.replaceAll(' ', ''))) {
            errorMsg = ERROR_MESSAGE.VISA_NUMBER;
          }
          break;
        case 'dayExpiry':
          const date = moment(
            `${moment().format('YYYY').substring(0, 2) + value}-${
              objectVal.month_exp
            }-01 `
          );
          const now = moment();

          if (now > date) {
            errorMsg = ERROR_MESSAGE.EXP_DATE;
          }
          break;
        case 'maxlengthCode':
          if (value.length !== 3) {
            errorMsg =
              languageDetectUrl() === 'ja'
                ? ERROR_MESSAGE.VISA_CODE
                : 'รหัสความปลอดภัยไม่ถูกต้อง';
          }
          break;
        default:
          break;
      }
      if (errorMsg) {
        error[key] = errorMsg;
      }
    }
  }
  return error;
};

export const generateListPlayerWithPosition = (numberPlayer, position) => {
  let players = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numberPlayer; i++) {
    players = [
      ...players,
      {
        position_num: i,
        position,
        team_id: '',
        uniform_number: '',
      },
    ];
  }

  return players;
};

export const formatCurrentcy = (num) =>
  num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;

export const mixCountdown = (start_at) => {
  let  nowTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
  nowTime = new Date(nowTime);
  let period = moment(start_at).utcOffset('+07:00').valueOf() - nowTime.getTime();
  const cDay = Math.floor(period / (1000 * 60 * 60 * 24));
  period -= cDay * (1000 * 60 * 60 * 24);
  
  const cHour = Math.floor(period / (1000 * 60 * 60));
  period -= cHour * (1000 * 60 * 60);

  const cMinute = Math.floor(period / (1000 * 60));
  period -= cMinute * (1000 * 60);

  const cSecond = Math.floor(period / 1000);

  return {
    day: cDay,
    hour: cHour,
    minute: cMinute,
    seconse: cSecond,
  };
};

export const updatePlayer = (sourceArr, itemRep) =>
  sourceArr.map((item) =>
    item.position_num === itemRep.position_num ? itemRep : item
  );

// eslint-disable-next-line import/no-dynamic-require
export const uniformPath = (id) => require(`../assets/uniform/team_${id}.png`);

export const renderSelectDate = (number, status = false) => {
  const dateTmp = [];
  let i = 0;

  while (i < number) {
    if (!status) i += 1;
    const day = i < 10 ? `0${i}` : i;
    dateTmp.push({ value: String(day), text: day });
    if (status) i += 1;
  }

  return dateTmp;
};

export const getValueMax = (arrays, item) => {
  let tmp = 0;
  arrays.forEach((result) => {
    if (result <= item) tmp = result;
  });

  return tmp;
};

export const getMaxRankTeam = (arrays) => {
  if(arrays.length === 0) {
    return {
      max: 0,
      max_second: 0
    };
  }
  let max_second = 0;
  const max = arrays.reduce((a, b) => (a.point > b.point ? a : b)).point;
  const array_tmps = _.sortBy(arrays, ['point']);
  array_tmps.forEach((result) => {
    if (result.point && result.point !== max) max_second = result.point;
  });

  return {
    max,
    max_second,
  };
};

export const renderNoneRoom = (room) => {
  return {
    football_fw: generateListPlayerWithPosition(room.football_fw, 'football_fw'),
    football_mf: generateListPlayerWithPosition(room.football_mf, 'football_mf'),
    football_df: generateListPlayerWithPosition(room.football_df, 'football_df'),
    football_gk: generateListPlayerWithPosition(room.football_gk, 'football_gk'),
  }
};

export const updateDataRoom = (item, number, position) => {
  const players = [];
  for(let i = 0; i < number; i++) {
    const key = _.findIndex(item, function(o) { return o.position_num === i; });
    if(Number(key) !== -1) {
      players.push(item[key])
    } else  {
      players.push({
        position_num: i,
        position,
        team_id: '',
        uniform_number: '',
      })
    }
  }

  return players;
}

// vr_bk
export const renderNameRoom = (name_session, lang = 'th', player_number = '') => {
  let names = [
    { th: 'รอบแบ่งกลุ่ม นัดที่ 1', ja: '第1節' },
    { th: 'รอบแบ่งกลุ่ม นัดที่ 2', ja: '第2節' },
    { th: 'รอบแบ่งกลุ่ม นัดที่ 3', ja: '第3節' },
    { th: 'รอบ16ทีมสุดท้าย', ja: 'ベスト16' },
    { th: 'รอบก่อนรองชนะเลิศ(รอบ8ทีมสุดท้าย)', ja: '準々決勝' },
    { th: 'รอบรองชนะเลิศ(รอบ 4 ทีมสุดท้าย)', ja: '準決勝' },
    { th: 'การแข่งตัดสินอันดับที่ 3,รอบชิงชนะเลิศ', ja: '3位決定戦、決勝' },
    { th: 'การแข่งตัดสินอันดับที่ 3,รอบชิงชนะเลิศ', ja: '3位決定戦、決勝' }
  ]
  
  if (name_session !== '') {
    const number = name_session.replace(/\D/g,'')
    
    if(number > 0 && number < 9) {
      let name = names[parseInt(number) - 1][lang]

      if(player_number !== '') {
        name = `${name} (${player_number} ${lang === 'th' ? 'คน' : '名'})`
      }
      return name
    } 
  } return ''
}

export const renderTeamName = (name, lang = 'th') => {
  const lists = {
    'Belgium': { th: 'เบลเยียม', ja: 'ベルギー'},
    'France': { th: 'ฝรั่งเศส', ja: 'フランス'},
    'Croatia': { th: 'โครเอเชีย', ja: 'クロアチア'},
    'Brazil': { th: 'บราซิล', ja: 'ブラジル'},
    'Uruguay': { th: 'อุรุกวัย', ja: 'ウルグアイ'},
    'Spain': { th: 'สเปน', ja: 'スペイン'},
    'England': { th: 'อังกฤษ', ja: 'イングランド'},
    'Japan': { th: 'ญี่ปุ่น', ja: '日本'},
    'Senegal': { th: 'เซเนกัล', ja: 'セネガル'},
    'Serbia': { th: 'เซอร์เบีย', ja: 'セルビア'},
    'Switzerland': { th: 'สวิตเซอร์แลนด์', ja: 'スイス'},
    'Mexico': { th: 'เม็กซิโก', ja: 'メキシコ'},
    'Korea': { th: 'เกาหลีใต้', ja: '韓国'},
    'Australia': { th: 'ออสเตรีย', ja: 'オーストリア'},
    'Denmark': { th: 'เดนมาร์ก', ja: 'デンマーク'},
    'Iran': { th: 'อืหร่าน', ja: 'イラン'},
    'Saudi Arabia': { th: 'ซาอุดีอาระเบีย', ja: 'サウジアラビア'},
    'Poland': { th: 'โปแลนด์', ja: 'ポーランド'},
    'Germany': { th: 'เยอรมนี', ja: 'ドイツ'},
    'Argentina': { th: 'อาร์เจนตินา', ja: 'アルゼンチン'},
    'Portugal': { th: 'โปรตุเกส', ja: 'ポルトガル'},
    'Tunisia': { th: 'ตูนิเซีย', ja: 'チュニジア'},
    'Costa Rica': { th: 'คอสตาริกา', ja: 'コスタリカ'},
    'Morocco': { th: 'โมร็อกโก', ja: 'モロッコ'},
    'Wales': { th: 'เวลส์', ja: 'ウェールズ'},
    'Netherlands': { th: 'เนเธอร์แลนด์', ja: 'オランダ'},
    'Ghana': { th: 'กานา', ja: 'ガーナ'},
    'Cameroon': { th: 'แคเมอรูน', ja: 'カメルーン'},
    'Qatar': { th: 'กาตาร์', ja: 'カタール'},
    'Ecuador': { th: 'เอกวาดอร์', ja: 'エクアドル'},
    'USA': { th: 'สหรัฐอเมริกา', ja: 'アメリカ'},
    'Canada': { th: 'แคนาดา', ja: 'カナダ'}
  }

  if (lists[name]) {
    return lists[name][lang];
  }
  return name;
}

export const TeamGroups = {
  'A': ['Ecuador', 'Netherlands', 'Senegal', 'Qatar'],
  'B': ['England', 'Iran', 'USA', 'Wales'],
  'C': ['Argentina', 'Mexico', 'Poland', 'Saudi Arabia'],
  'D': ['Australia', 'Denmark', 'France', 'Tunisia'],
  'E': ['Costa Rica', 'Germany', 'Japan', 'Spain'],
  'F': ['Belgium', 'Canada', 'Croatia', 'Morocco'],
  'G': ['Brazil', 'Cameroon', 'Serbia', 'Switzerland'],
  'H': ['Ghana', 'South Korea', 'Portugal', 'Uruguay']
}
