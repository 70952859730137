import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  isProcessing: false,
  token: '',
  errorsRequest: [],
  payloadSignup: {},
  mypage: {},
  withDraw: [],
  prize: {},
  bankAccount: {},
  curentUser: {},
  promotionCode: {},
  amountSuccess: {},
  requestAmount: 0,
  earned: [],
};

const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    signInRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    signInRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.token = action?.data?.auth_token;
      state.userInfo = action?.data;
    },
    signInRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },
    loginFacebookRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    loginFacebookRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.token = action?.data?.auth_token;
      state.userInfo = action?.data;
    },
    loginFacebookRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },
    signUpPayload: (state, action) => {
      state.payloadSignup = action.payload;
    },

    logoutRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    logoutRequestSuccess: (state, action) => {
      state.type = action.type; 
      state.isProcessing = false;
      // state.userInfo = {};
      // state.token = '';
    },

    logoutRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    signUpRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    signUpRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    signUpRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors.full_messages;
    },
    updatePassword: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    updatePasswordSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    updatePasswordFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    verifyPhoneCodeRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    verifyPhoneCodeRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.token = action?.data?.auth_token;
      state.userInfo = action?.data;
    },

    verifyPhoneCodeRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    resetPasswordRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    resetPasswordRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    resetPasswordRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    changePasswordRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    changePasswordRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    changePasswordRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    mypageRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    mypageRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.mypage = action.data;
      state.infoAccount = action.data.user_info;
    },

    mypageRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    withDrawRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    withDrawRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.withDraw = action.data;
    },

    withDrawRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    prizeRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    prizeRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.prize = action.data;
    },

    prizeRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    getCurrentUser: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getCurrentUserSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.curentUser = action.data;
    },

    getCurrentUserFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    updateProfile: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    updateProfileSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.curentUser = action.data;
    },

    updateProfileFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    promotionCodeRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    promotionCodeRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.promotionCode = action.data;
    },

    promotionCodeRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    getBankAccountRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getBankAccountRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.bankAccount = action.data.data ? action?.data?.data : {};
    },

    getBankAccountRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    saveBankAccountRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    saveBankAccountRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.bankAccount = action.data.data;
    },

    saveBankAccountRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    withDrawMoney: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    withDrawMoneySuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
      state.amountSuccess = action?.data?.data;
    },
    withDrawMoneyFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    earnedRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    earnedRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
      state.earned = action?.data;
    },
    earnedRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    changeLanguageRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    changeLanguageRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    changeLanguageRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    resetData: (state) => {
      state.errorsRequest = '';
      state.type = '';
    },
    requestAmount: (state, action) => {
      state.requestAmount = action.payload;
    },
  },
});

const { actions, reducer } = accountSlice;

export const {
  signInRequest,
  signInSuccess,
  signInFailed,
  logoutRequest,
  logoutRequestSuccess,
  logoutRequestFailed,
  signUpPayload,
  signUpRequest,
  signUpRequestSuccess,
  signUpRequestFailed,
  verifyPhoneCodeRequest,
  verifyPhoneCodeRequestSuccess,
  verifyPhoneCodeRequestFailed,
  resetPasswordRequest,
  resetPasswordRequestSuccess,
  resetPasswordRequestFailed,
  changePasswordRequest,
  changePasswordRequestSuccess,
  changePasswordRequestFailed,
  mypageRequest,
  mypageRequestSuccess,
  mypageRequestFailed,
  withDrawRequest,
  withDrawRequestSuccess,
  withDrawRequestFailed,
  prizeRequest,
  prizeRequestSuccess,
  prizeRequestFailed,
  promotionCodeRequest,
  promotionCodeRequestSuccess,
  promotionCodeRequestFailed,
  getBankAccountRequest,
  getBankAccountRequestSuccess,
  getBankAccountRequestFailed,
  saveBankAccountRequest,
  saveBankAccountRequestSuccess,
  saveBankAccountRequestFailed,
  resetData,
  getCurrentUser,
  updateProfile,
  updatePassword,
  withDrawMoney,
  requestAmount,
  earnedRequest,
  earnedRequestSuccess,
  earnedRequestFailed,
  loginFacebookRequest,
  changeLanguageRequest
} = actions;

export default reducer;
