const ERROR_MESSAGE = {
  EMAIL: 'メール形式が正しくありません。',
  PASSWORD: 'パスワードに使用できない文字が含まれています。',
  REQUIRED_JP: '※必須項目です。入力してください。', // assume
  MIN_LENGTH_PASSWORD_JP: 'パスワードは6文字以上で設定してください。',
  MIN_LENGTH_PASSWORD_TH: 'กรุณาใส่รหัสผ่าน 6 หลักขึ้นไป',
  MIN_LENGTH_USERNAME_JP: 'ตั้งค่าชื่อผู้ใช้ด้วยอักขระอย่างน้อย 6 ตัว',
  USER_NAME: 'ユーザー名に使用できない文字が含まれています。',
  MAX_LENGTH_USER_JP: 'ユーザ名は20文字以内で入力してください',
  MAX_LENGTH_USER_TH: 'กรุณาใส่ตัวอักษรไม่เกิน 20 ตัว',
  NOT_CONFIRM_PASSWORD: '※パスワードが一致していません。',
  VISA_NUMBER: 'クレジットカード番号が正しくありません',
  VISA_CODE: 'セキュリティーコードが正しくありません',
  VISA_DATE_EXPIRY: '有効期限が正しくありません',
  EXP_DATE: '有効期限が正しくありません',
  USER_NAME_EMPTY: 'ユーザー名を入力して下さい',
  PHONE_NUMBER: 'Invalid phone number format.', // assume
  NUMBER: 'Invalid number format.', // assume
  PASSWORD_NOT_MATCH: 'Passwords do not match.',
  PROMOTION_CODE_FAIL: 'プロモーションコードが正しくありません',
};

export default ERROR_MESSAGE;
