/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useState, useEffect } from 'react';
import Input from 'commons/components/Input';
import Loading from 'commons/components/Loading';
import images from 'themes/images';
import Language from 'commons/components/Language';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ROUTERS from 'constants/routers';
import { validator } from 'helpers';
import { API } from 'api';
import ModalConfirm from 'commons/components/Modal/ModalConfirm';
import Template from '../template';
import { verifyPhoneCodeRequest, resetData } from '../../redux';

type Props = {
  history: {
    push: Function,
  },
};

const VerifyPhoneCode = ({ history }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { type, isProcessing, errorsRequest, token, userInfo } = useSelector(
    (state) => state.account,
    shallowEqual
  );

  const [error, setError] = useState({});

  const [phoneCode, setPhoneCode] = useState('');

  useEffect(() => {
    switch (type) {
      case 'accounts/verifyPhoneCodeRequestSuccess':
        API.setHeader('access-token', token);
        API.setHeader('uid', userInfo?.uid);
        API.setHeader('client', userInfo?.client_id);
        history.push(ROUTERS.CONFRIM_SIGNUP_SUCCESS);
        break;

      default:
        break;
    }
  }, [type]);

  const rules = {
    phoneCode: ['required'],
  };
  const handleVerrifyPhonecode = () => {
    let validation: {
      phoneCode?: number,
    } = {};

    validation = validator({ phoneCode }, rules);

    if (Object.keys(validation).length > 0) {
      setError(validation);
      return;
    }

    setError({});

    dispatch(
      verifyPhoneCodeRequest({
        invite_cd: null,
        sms_code: phoneCode,
      })
    );
  };

  const renderErrorsRequest =
    errorsRequest &&
    errorsRequest.map((errorMsg) => <p>{errorMsg?.error_type}</p>);

  return (
    <Template
      title={t('sendCode')}
      isShowBtnLoginLine={false}
      isShowLine={false}
    >
      <Language />
      {isProcessing && <Loading />}
      <div className="account-page__title__text">
        <p>電話番号宛にメッセージを送信しました。</p>
        <p>記載された認証コードを入力してください。</p>
      </div>
      {renderErrorsRequest && !error.phoneCode ? (
        <div className="error-request">{renderErrorsRequest}</div>
      ) : (
        ''
      )}
      <div className="sign-up__form">
        <div className="sign-up__form__email">
          <Input
            icon={images.phone_icon.default}
            type="number"
            onChange={(e) => setPhoneCode(e.target.value)}
            name="name"
            defaultValue={phoneCode}
            placeholder={t('placeholderPhoneCode')}
            errorMsg={t(error.phoneCode)}
          />
        </div>
        <div className="sign-up__form__button-wrapper">
          <button onClick={handleVerrifyPhonecode}>
            {t('btnSendCodeText')}
          </button>
        </div>
        <div className="login__link">
          <button
            className="login__link__text register-link"
            onClick={() => {
              history.push(ROUTERS.SIGN_UP);
            }}
          >
            {t('resendCode')}
          </button>
        </div>

        <ModalConfirm
          title="การตรวจสอบสิทธิ์ SMS ล้มเหลว"
          textBtnSubmit="ตกลง"
          handleSubmit={() => dispatch(resetData())}
          isShowModal={errorsRequest !== ''}
        >
          <div className="error-verify-code">
            認証に失敗しました。
            <br />
            認証コードが間違っている、
            <br />
            もしくは既に認証済みの可能性があります。
            <br />
            認証コードをご確認の上、もう一度入力してください。
          </div>
        </ModalConfirm>
      </div>
    </Template>
  );
};

export default React.memo<Props>(VerifyPhoneCode);
