/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loading from 'commons/components/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { API } from 'api';
import { logoutRequest } from 'modules/account/redux';
import ROUTERS from 'constants/routers';

import VerifyPhoneCode from 'modules/account/components/signUp/VerifyPhoneCode';
import ConfirmSignupSuccess from 'modules/account/components/signUp/ConfirmSignupSuccess';
import PrivateRoute from './PrivateRoute';

/* Authentication */
const Login = lazy(() => import('modules/account/components/login'));
const Home = lazy(() => import('modules/home/components'));
const Signup = lazy(() => import('modules/account/components/signUp'));
const Phone = lazy(() => import('modules/account/components/signUp/Phone'));
const DraftGame = lazy(() => import('modules/home/components/draftGame'));
const DraftTop = lazy(() => import('modules/home/components/draftGame/top'));
const ContactForm = lazy(() => import('modules/contact/components/form'));
const ContactQuestion = lazy(() => import('modules/contact/components/answer'));
const ContactAnswer = lazy(() => import('modules/contact/components/answer'));
const ResetPassword = lazy(() =>
  import('modules/account/components/resetPassword')
);
const ChangePassword = lazy(() =>
  import('modules/account/components/changePassword')
);

/* Player */
const ChoosePlayer = lazy(() => import('modules/home/components/choosePlayer'));

/* Payment */
const PaymentTop = lazy(() => import('modules/payment/components/paymentTop'));
const PaymentPromotionCode = lazy(() => import('modules/payment/components/paymentPromotionCode'));
const PaymentDebit = lazy(() =>
  import('modules/payment/components/paymentDebit')
);

/** Mypage */
const Mypage = lazy(() => import('modules/account/components/mypage'));
const MypagePrize = lazy(() =>
  import('modules/account/components/mypage/prize')
);
const PromotionCode = lazy(() =>
  import('modules/account/components/promotionCode/list')
);
const PromotionCodeStatus = lazy(() =>
  import('modules/account/components/promotionCode/status')
);
const MyPageEdit = lazy(() => import('modules/account/components/mypage/edit'))
const InformationEdit = lazy(() =>
  import('modules/account/components/informationEdit')
);
const InformationEditFrom = lazy(() =>
  import('modules/account/components/informationEdit/form')
);

const PrizesDetail = lazy(() =>
  import('modules/account/components/mypage/prizeDetail')
);

const PrizeDetailConfirm = lazy(() =>
  import('modules/account/components/mypage/prizeDetail/PrizeDetailConfirm')
);
const PrizeConfirmationSuccess = lazy(() =>
  import(
    'modules/account/components/mypage/prizeDetail/PrizeConfirmationSuccess'
  )
);

const ResultFootball = lazy(() => import('modules/ranking/components'));
const UserRankingDetail = lazy(() =>
  import('modules/ranking/components/tab/userRankingDetail')
);

/** Ranking */
const RankingDetail = lazy(() => import('modules/ranking/components/detail'));
const RankingTeamDetail = lazy(() => import('modules/ranking/components/detailTeam'));
const ScorePlayer = lazy(() => import('modules/ranking/components/score'));

/** Page */
const HowToPlay = lazy(() => import('modules/page/components/howToPlay'));
const HowToPoint = lazy(() => import('modules/page/components/pointTable'));
const TermsOfService = lazy(() => import('modules/page/components/termsOfService'));
const PrivacyPolicy = lazy(() => import('modules/page/components/privacyPolicy'));
const PaymentPage = lazy(() => import('modules/page/components/payment'));
const PaymentType = lazy(() => import('modules/payment/components/paymentType'));
const MaintancePage = lazy(() => import('modules/page/components/maintance'));
const InviteMember = lazy(() => import('modules/page/components/inviteMember'));
const NotFound = lazy(() => import('modules/page/components/notfound'));
const PaymentProcess = lazy(() => import('modules/payment/components/paymentProcess'));
const HowToTitketFollow = lazy(() =>
  import('modules/page/components/ticketFlow')
);
const Notices = lazy(() => import('modules/notices/components'));
const HowToTitketFollowStep1 = lazy(() =>
  import('modules/page/components/ticketFlowStep1')
);
const HowToTitketFollowStep2 = lazy(() =>
  import('modules/page/components/ticketFlowStep2')
);
const HowToTitketFollowStep3 = lazy(() =>
  import('modules/page/components/ticketFlowStep3')
);
const PaymentBanking = lazy(() =>
  import('modules/payment/components/PaymentBanking')
);
const PaymentBankingSuccess = lazy(() =>
  import('modules/payment/components/PaymentBankingSuccess')
);

const Router = () => {
  const dispatch = useDispatch();
  const { token, userInfo } = useSelector((state) => state.account);
  const isAuthenticated = token !== '';
  if (token) {
    API.setHeader('access-token', token);
    API.setHeader('uid', userInfo?.uid);
    API.setHeader('client', userInfo?.client_id);
    API.setHeader('expiry', userInfo?.expiry);
    API.setHeader(`token-type`, `Bearer`);
  }

  const getUniformUrl = () => {
    const query = new URLSearchParams(window.location.search);
    if(query.get('utm_source')) {
      localStorage.setItem('utm_source', query.get('utm_source'))
      localStorage.setItem('utm_medium', query.get('utm_medium'))
      localStorage.setItem('utm_campaign', query.get('utm_campaign'))
    }
  }
 
  getUniformUrl();
  
  useEffect(() => {
    const authInterceptor = API.axiosInstance.interceptors.response.use(
      (response) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
        return Promise.reject(response);
      },
      (error) => {
        const { status } = error.response;
        if (status === 401) {
          dispatch(logoutRequest());
        }
        return error;
      }
    );
    return () => {
      API.axiosInstance.interceptors.request.eject(authInterceptor);
    };
  }, [token]);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.HOME}
            component={Home}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact
            path={ROUTERS.CHOOSE_PLAYER}
            component={ChoosePlayer}
          />
          <Route exact path={ROUTERS.SIGN_UP} component={Signup} />
          <Route
            exact
            path={ROUTERS.RESET_PASSWORD}
            component={ResetPassword}
          />
          <Route
            exact
            path={ROUTERS.MAINTANCE_PAGE}
            component={MaintancePage}
          />

          <PrivateRoute
            exact
            path={ROUTERS.DRAFT_GAME}
            component={DraftGame}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            exact
            path={ROUTERS.DRAFT_TOP}
            component={DraftTop}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.MY_PAGE_EDIT}
            component={MyPageEdit}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.PRIZE_DETAIL}
            component={PrizesDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.PRIZE_DETAIL_CONFIRM}
            component={PrizeDetailConfirm}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.RESULT_FOOTBALL}
            component={ResultFootball}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.NOTICES}
            component={Notices}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.INVITE_MEMBER}
            component={InviteMember}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.USER_RANKING_DETAIL}
            component={UserRankingDetail}
            isAuthenticated={isAuthenticated}
          />

          <Route
            exact
            path={ROUTERS.CHANGE_PASSWORD}
            component={ChangePassword}
          />
          <Route exact path={ROUTERS.PHONE_INPUT} component={Phone} />
          <Route
            exact
            path={ROUTERS.VERIFY_PHONE_CODE}
            component={VerifyPhoneCode}
          />
          <Route
            exact
            path={ROUTERS.CONFRIM_SIGNUP_SUCCESS}
            component={ConfirmSignupSuccess}
          />
          <Route
            exact
            path={ROUTERS.PRIZE_CONFIRM_SUCCESS}
            component={PrizeConfirmationSuccess}
          />
          <PrivateRoute 
            exact path={ROUTERS.MY_PAY} 
            component={Mypage} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.MY_PAY_PRIZE} 
            component={MypagePrize} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.PROMOTION_CODE}
            component={PromotionCode}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.PROMOTION_CODE_STATUS}
            component={PromotionCodeStatus}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.INFORMATION_EDIT}
            component={InformationEdit}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.INFORMATION_EDIT_FORM}
            component={InformationEditFrom}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.RANKING_DETAIL}
            component={RankingDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.RANKING_TEAM_DETAIL}
            component={RankingTeamDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.GAME_SCORE_PLAYER}
            component={ScorePlayer}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.HOW_TO_PLAY} 
            component={HowToPlay} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.HOW_TO_POINT} 
            component={HowToPoint} 
            isAuthenticated={isAuthenticated}
          />
          <Route 
            exact 
            path={ROUTERS.TERMS_OF_SERVICE} 
            component={TermsOfService} 
          />
          <Route 
            exact 
            path={ROUTERS.PRIVACY_POLICY} 
            component={PrivacyPolicy} 
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_PAGE} 
            component={PaymentPage} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_BANKING} 
            component={PaymentBanking} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_RESULT} 
            component={PaymentBankingSuccess} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.HOW_TO_TITKET_FOLLOW}
            component={HowToTitketFollow}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.HOW_TO_TITKET_FOLLOW_STEP_1}
            component={HowToTitketFollowStep1}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.HOW_TO_TITKET_FOLLOW_STEP_2}
            component={HowToTitketFollowStep2}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ROUTERS.HOW_TO_TITKET_FOLLOW_STEP_3}
            component={HowToTitketFollowStep3}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_TOP} 
            component={PaymentTop} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_PROCESS} 
            component={PaymentProcess} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_TYPE} 
            component={PaymentType} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_DEBIT} 
            component={PaymentDebit} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.PAYMENT_PROMOTION_CODE} 
            component={PaymentPromotionCode} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.CONTACT_FORM} 
            component={ContactForm} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.CONTACT_QUESTION} 
            component={ContactQuestion} 
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute 
            exact 
            path={ROUTERS.CONTACT_ANSWER} 
            component={ContactAnswer} 
            isAuthenticated={isAuthenticated}
          />
          <Route exact path={ROUTERS.LOGIN} component={Login} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
