const ROUTERS = {
  LOGIN: `/login`,
  HOME: `/`,
  SIGN_UP: `/signup`,
  RESET_PASSWORD: `/reset_password`,
  CHANGE_PASSWORD: `/change_password`,
  PHONE_INPUT: `/phone-input`,
  VERIFY_PHONE_CODE: `/verify-phone-code`,
  CONFRIM_SIGNUP_SUCCESS: `/confirm-signup-success`,
  CHOOSE_PLAYER: `/choose-player/:id`,
  PAYMENT_TOP: `/payment-top`,
  PAYMENT_DEBIT: `/payment-debit`,
  MY_PAY: `/my-page`,
  MY_PAY_PRIZE: `/my-page-prize`,
  INFORMATION_EDIT: `/account-information`,
  INFORMATION_EDIT_FORM: `/account-information-edit`,
  PROMOTION_CODE: `/promotion-code`,
  PROMOTION_CODE_STATUS: `/promotion-code-status`,
  DRAFT_GAME: `/draft-game/:id`,
  DRAFT_TOP: `/draft-top/:id`,
  MY_PAGE_EDIT: `/my-page-edit`,
  PRIZE_DETAIL: `/my-page-prize-detail`,
  PRIZE_DETAIL_CONFIRM: `/my-page-prize-detail-confirm`,
  PRIZE_CONFIRM_SUCCESS: `/my-page-prize-success`,
  RANKING_DETAIL: `/ranking/:roomId/:teamId`,
  RANKING_TEAM_DETAIL: `/ranking-team/:roomId/:teamId`,
  GAME_SCORE_PLAYER: `/score-player/:gameId/:memberId`,
  RESULT_FOOTBALL: `/result-football`,
  USER_RANKING_DETAIL: `/user-ranking-detail/:roomId/:teamId/:userId`,
  HOW_TO_PLAY: `/how-to-play`,
  HOW_TO_POINT: `/how-to-play/point-table`,
  HOW_TO_TITKET_FOLLOW: `/how-to-play/ticket-flow`,
  NOTICES: `/notices`,
  HOW_TO_TITKET_FOLLOW_STEP_1: `/how-to-play/ticket-flow/step-1`,
  HOW_TO_TITKET_FOLLOW_STEP_2: `/how-to-play/ticket-flow/step-2`,
  HOW_TO_TITKET_FOLLOW_STEP_3: `/how-to-play/ticket-flow/step-3`,
  TERMS_OF_SERVICE: `/terms-of-service`,
  PRIVACY_POLICY: `/privacy-policy`,
  PAYMENT_PAGE: `/payment-page`,
  MAINTANCE_PAGE: `/maintenance`,
  LOGIN_TEST: `/login-test`,
  PAYMENT_PROCESS: `/payment-process`,
  PAYMENT_TYPE: `/payment-type`,
  PAYMENT_BANKING: `/payment-banking`,
  PAYMENT_RESULT: `/payment-result`,
  CONTACT_FORM: `/contact-form`,
  CONTACT_ANSWER: `/contact-answer`,
  CONTACT_QUESTION: `/contact-detail/:id`,
  INVITE_MEMBER: `/invite-members`,
  PAYMENT_PROMOTION_CODE: `/payment-promotion-code`
};

export default ROUTERS;
