/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
/* eslint-disable radix */
/* eslint-disable no-lonely-if */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

// worker Saga: will be fired on SIGN_UP actions
function* getRooms(action) {
  try {
    const response = yield call(() => API.get(ROUTES.GET_ROMS(action.payload)));

    if (response.ok) {
      const { data } = response.data;
      // let dataPlayerList3 = [];
      // let dataPlayerList11 = [];
      const myTeamHistories3 = {};
      const myTeamHistories11 = {};
      let isCanNext = false;
      const dataPlayerList = [];
      for (let i = 0; i < data.length; i++) {
        let playersRes;

        if (data[i].myteam_history_id) {
          playersRes = yield call(() =>
            API.get(ROUTES.HISTORY_PLAYERS(data[i].myteam_history_id))
          );

          if (playersRes) {
            // if (data[i].choice_teams === 3) {
            //   dataPlayerList3 = playersRes?.data?.data;
            //   isCanNext = (playersRes?.data?.data.length >= 3 && Number(data[i].current_user_confirm_flg) !== 1);
            // }

            // if (data[i].choice_teams === 11) {
            //   dataPlayerList11 = playersRes?.data?.data;
            //   isCanNext = (playersRes?.data?.data.length >= 11 && Number(data[i].current_user_confirm_flg) !== 1);
            // }
            isCanNext = (playersRes?.data?.data.length >= (parseInt(data[i].football_df) + parseInt(data[i].football_fw) + parseInt(data[i].football_gk) + parseInt(data[i].football_mf)) && Number(data[i].current_user_confirm_flg) !== 1);
            dataPlayerList.push({ type: data[i].choice_teams, data: playersRes?.data?.data })
          }
        } else {
          isCanNext = false;
          dataPlayerList.push({ type: data[i].choice_teams, data: [] })
        }

      }

      // In case: signup request success
      yield put({
        type: 'home/getRoomsSuccess',
        data,
        // dataPlayerList3,
        // dataPlayerList11,
        dataPlayerList,
        myTeamHistories11,
        myTeamHistories3,
        isCanNext,
      });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/getRoomsFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/getRoomsFailed' });
  }
}

function* getRoomsSaga() {
  yield takeLatest('home/getRooms', getRooms);
}

function* getGameInfo(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_GAME_SESSIONS(action.payload.gameSecttion))
    );

    if (response.ok) {
      const { data } = response.data;
      const teamIds = [];
      yield put({ type: 'home/getSearchTeamSuccess', data });

      data.forEach((item) => {
        teamIds.push(item.home_team_id);
        teamIds.push(item.away_team_id);
      });

      const playerList = yield call(() =>
        API.get(ROUTES.GET_PLAYER_LIST(action.payload.roomId), {
          position: 'football_fw',
          search_word: '',
          team_ids: `"[${teamIds}]"`,
          page: 0,
        })
      );

      if (playerList.ok) {
        // In case: signup request success
        yield put({
          type: 'home/getGameInfoSuccess',
          playerList: playerList?.data?.data,
          teamIds,
        });
      }
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/getGameInfoFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/getGameInfoFailed' });
  }
}

function* getGameInfoSaga() {
  yield takeLatest('home/getGameInfo', getGameInfo);
}

// worker Saga: will be fired on SIGN_UP actions
function* getListPlayers(action) {
  try {
    const { teamIds, positionSearch, roomId, currentPage, searchWord, playerIds } =
      action.payload;
    const response = yield call(() =>
      API.get(ROUTES.GET_PLAYER_LIST(roomId), {
        position: searchWord !== '' ? 'football' : positionSearch,
        search_word: searchWord,
        team_ids: `"[${teamIds}]"`,
        player_ids: `"[${playerIds}]"`,
        page: currentPage,
      })
    );

    if (response.ok) {
      const data =
        parseInt(response?.data?.status, 10) === 204
          ? response?.data?.status
          : response?.data?.data;
      // In case: signup request success
      yield put({ type: 'home/getListPlayersSuccess', data, currentPage });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/getListPlayersFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/getListPlayersFailed' });
  }
}

function* getListPlayersSaga() {
  yield takeLatest('home/getListPlayers', getListPlayers);
}

// worker Saga: will be fired on SIGN_UP actions
function* updatePlayerHistory(action) {
  try {
    const { id, playerId, position_num } = action.payload;
    const response = yield call(() =>
      API.patch(
        ROUTES.UPDATE_MY_TEAM(id),
        JSON.stringify({ player_id: playerId, position_num })
      )
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'home/updatePlayerHistorySuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/updatePlayerHistoryFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/updatePlayerHistoryFailed' });
  }
}

function* updatePlayerHistorySaga() {
  yield takeLatest('home/updatePlayerHistory', updatePlayerHistory);
}

function* addPlayerToHistory(action) {
  const { isUtility, playerId, positionNum, myTeamId } = action.payload;
  try {
    const response = yield call(() =>
      API.post(
        ROUTES.HISTORY_PLAYERS(myTeamId),
        JSON.stringify({
          is_utility: isUtility,
          player_id: playerId,
          position_num: positionNum,
        })
      )
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({
        type: 'home/addPlayerToHistorySuccess',
        data,
      });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/addPlayerToHistoryFailed',
        rrors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/addPlayerToHistoryFailed' });
  }
}

function* addPlayerToHistorySaga() {
  yield takeLatest('home/addPlayerToHistory', addPlayerToHistory);
}

function* getListHistoryPlayer(action) {
  try {
    const { myTeamId, teamIds, positionSearch, roomId } =
      action.payload;
    const response = yield call(() =>
      API.get(ROUTES.HISTORY_PLAYERS(myTeamId))
    );

    if (response.ok) {
      const room_response = yield call(() =>
        API.get(ROUTES.GET_ROOM_BY_ID(roomId))
      );
      const { data } = response.data;
      const room_data = room_response.data.data;
      let listPlayerRes;
      if (teamIds && teamIds.length > 0)
        listPlayerRes = yield call(() =>
          API.get(ROUTES.GET_PLAYER_LIST(roomId), {
            position: positionSearch,
            search_word: '',
            team_ids: `"[${teamIds}]"`,
            page: 0,
          })
        );
      // let dataPlayerList3 = [];
      // let dataPlayerList11 = [];
      let isCanNext = false;

      // if (typeMatch === 3) {
      //   dataPlayerList3 = data;
      //   isCanNext = (data.length >= 3 && !room_data.current_user_confirm_flg);
      // }
      // if (typeMatch === 11) {
      const dataPlayer = data;
      isCanNext = (data.length >= (parseInt(room_data.football_df) + parseInt(room_data.football_fw) + parseInt(room_data.football_gk) + parseInt(room_data.football_mf)) && !room_data.current_user_confirm_flg);
      // }

      // In case: signup request success
      yield put({
        type: 'home/getListHistoryPlayerSuccess',
        dataPlayer,
        room: room_data,
        playerList: listPlayerRes?.data?.data,
        isCanNext,
      });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/getListHistoryPlayerFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/getListHistoryPlayerFailed' });
  }
}

function* getListHistoryPlayerSaga() {
  yield takeLatest('home/getListHistoryPlayer', getListHistoryPlayer);
}

function* getPrizesGame(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_PRIZES_GAME(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({
        type: 'home/getPrizesGameSuccess',
        data,
      });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/getPrizesGameFailed',
        rrors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/getPrizesGameFailed' });
  }
}

function* getPrizesGameSaga() {
  yield takeLatest('home/getPrizesGame', getPrizesGame);
}

function* getRoomById(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_ROOM_BY_ID(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;
      let playersRes;
      let myTeamHistoriesRes;
      if (data.myteam_history_id) {
        playersRes = yield call(() =>
          API.get(ROUTES.HISTORY_PLAYERS(data.myteam_history_id))
        );
      } else {
        myTeamHistoriesRes = yield call(() =>
          API.post(
            ROUTES.GET_MY_TEAM_HISTORY(data.id),
            JSON.stringify({ room_id: data.id })
          )
        );
        playersRes = yield call(() =>
          API.get(ROUTES.HISTORY_PLAYERS(myTeamHistoriesRes?.data?.data?.id))
        );
      }

      // let dataPlayerList3 = [];
      // let dataPlayerList11 = [];
      let isCanNext = false;

      // if (data?.choice_teams === 3) { 
      const dataPlayer = playersRes?.data?.data;
      //   isCanNext = (playersRes?.data?.data.length >= 3 && !data.current_user_confirm_flg);
      // }
      // if (data?.choice_teams === 11) {
      //   dataPlayerList11 = data;
      isCanNext = (playersRes?.data?.data.length >= (data.football_df + data.football_fw + data.football_gk + data.football_mf) && !data.current_user_confirm_flg);
      // }

      // In case: signup request success
      yield put({
        type: 'home/getRoomByIdSuccess',
        data,
        myTeamHistoriesRes,
        dataPlayer,
        // dataPlayerList3,
        // dataPlayerList11,
        isCanNext,
      });
    } else {
      // In case: signup request failed
      yield put({
        type: 'home/getRoomByIdFailed',
        rrors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'home/getRoomByIdFailed' });
  }
}

function* getRoomByIdSaga() {
  yield takeLatest('home/getRoomById', getRoomById);
}

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
function* chargeBeta(action) {
  try {
    const charge = yield call(() =>
      API.post(ROUTES.PAYMENT_CHARGE, action?.payload)
    );
    const { data } = charge.data;
    if (charge.ok) {
      yield put({ type: 'home/chargeBetaSuccess', data });
    } else {
      yield put({
        type: 'home/chargeBetaFailed',
        errors: charge?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'home/chargeBetaFailed' });
  }
}

/**
 * Dispatched `getDetailPlayer` action.
 */
function* chargeBetaSaga() {
  yield takeLatest('home/chargeBeta', chargeBeta);
}

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
function* saveMyTeamHistory(action) {
  try {
    const charge = yield call(() =>
      API.post(ROUTES.SAVE_MY_TEAM_HISTORY(action?.payload?.myteam_history_id), action?.payload)
    );
    const { data } = charge;
    if (charge.ok) {
      yield put({ type: 'home/saveMyTeamHistorySuccess', data });
    } else {
      if (Number(charge.status) === 400) {
        yield put({
          type: 'home/saveMyTeamHistoryFailedMaxPlayer',
          errors: charge?.data?.errors,
        });
      } else {
        yield put({
          type: 'home/saveMyTeamHistoryFailed',
          errors: charge?.data?.errors,
        });
      }
    }
  } catch (error) {
    yield put({ type: 'home/saveMyTeamHistoryFailed' });
  }
}

/**
 * Dispatched `getDetailPlayer` action.
 */
function* saveMyTeamHistorySaga() {
  yield takeLatest('home/saveMyTeamHistory', saveMyTeamHistory);
}

/**
* worker Saga: will be fired on getDetailPlayer actions
* 
* @param {*} action 
*/
function* saveAnalytics(action) {
  try {
    const charge = yield call(() =>
      API.post(ROUTES.SAVE_ANALYTICS, action?.payload)
    );
    const { data } = charge.data;
    if (charge.ok) {
      yield put({ type: 'home/saveAnalyticsSuccess', data });
    } else {
      yield put({
        type: 'home/saveAnalyticsFailed',
        errors: charge?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'home/saveAnalyticsFailed' });
  }
}

/**
   * Dispatched `getDetailPlayer` action.
   */
function* saveAnalyticsSaga() {
  yield takeLatest('home/saveAnalytics', saveAnalytics);
}

/**
 * worker Saga: will be fired on requestShare actions
 * 
 * @param {*} action 
 */
function* requestShare(action) {
  try {
    const charge = yield call(() =>
      API.post(ROUTES.SHARING_FACEBOOK, action?.payload)
    );
    const { data } = charge.data;
    if (charge.ok) {
      yield put({ type: 'home/shareSuccess', data });
    } else {
      yield put({
        type: 'home/shareFailed',
        errors: charge?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'home/shareFailed' });
  }
}

/**
 * Dispatched `requestShareSaga` action.
 */
function* requestShareSaga() {
  yield takeLatest('home/requestShare', requestShare);
}

/**
 * worker Saga: will be fired on requestShare actions
 * 
 * @param {*} action 
 */
 function* updateMaintainChecked(action) {
  try {
    const charge = yield call(() =>
      API.post(ROUTES.MAINTAINCE_CHECKED, action?.payload)
    );
    const { data } = charge.data;
    if (charge.ok) {
      yield put({ type: 'home/updateMaintainCheckedSuccess', data });
    } else {
      yield put({
        type: 'home/updateMaintainCheckedFailed',
        errors: charge?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'home/updateMaintainCheckedFailed' });
  }
}

/**
 * Dispatched `updateMaintainCheckedSaga` action.
 */
function* updateMaintainCheckedSaga() {
  yield takeLatest('home/updateMaintainChecked', updateMaintainChecked);
}

export {
  getRoomsSaga,
  getListPlayersSaga,
  getGameInfoSaga,
  addPlayerToHistorySaga,
  getListHistoryPlayerSaga,
  updatePlayerHistorySaga,
  getPrizesGameSaga,
  getRoomByIdSaga,
  chargeBetaSaga,
  saveMyTeamHistorySaga,
  saveAnalyticsSaga,
  requestShareSaga,
  updateMaintainCheckedSaga
};
