import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noticeList: [],
  isProcessing: false,
};

const noticeSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {
    getNoticeList: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getNoticeListSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.noticeList = action?.data;
    },

    getNoticeListFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    settingNotice: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    settingNoticeSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.noticeList = action?.data;
    },

    settingNoticeFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
  },
});

const { actions, reducer } = noticeSlice;

export const { getNoticeList, settingNotice } = actions;

export default reducer;
