// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import ROUTERS from 'constants/routers';
import Template from '../template';

type Props = {
  history: {
    push: Function,
  },
};
const ConfirmSignupSuccess = ({ history }: Props) => {
  const { t } = useTranslation();

  const handleConfirmSignupSuccess = () => {
    history.push(ROUTERS.HOME);
  };

  return (
    <Template isShowBtnLoginLine={false} isShowLine={false}>
      <div className="account-page__title__text">
        <p>現在、大型アップデート中のため</p>
        <p>ゲームを一時停止しております。</p>
      </div>
      <div className="account-page__title__text">
        <p>ゲーム開催まで</p>
        <p>今しばらくお待ちくださいませ。</p>
      </div>

      <div className="sign-up__form__button-wrapper">
        <button onClick={handleConfirmSignupSuccess}>{t('logoText')}</button>
      </div>
    </Template>
  );
};

export default React.memo<Props>(ConfirmSignupSuccess);
