import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentInfo: {},
  urlPayment: '',
  paymentStatus: '',
  isProcessing: false,
  promotionLists: []
};

const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    paymentRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    paymentRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.paymentInfo = action?.data;
    },

    paymentRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    paymentRequestMasterCard: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    paymentRequestMasterCardSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.paymentInfo = action?.data;
    },

    paymentRequestMasterCardFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    paymentBankingRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    paymentBankingRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.paymentInfo = action?.data;
    },

    paymentBankingRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },
    
    paymentStatusRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    paymentStatusRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.paymentStatus = action?.data?.payment_status;
    },

    paymentStatusRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    chillpayRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    chillpayRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.urlPayment = action?.data?.PaymentUrl;
    },

    chillpayRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },
    getListPromotion: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getListPromotionSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.promotionLists = action?.data;
    },
    getListPromotionFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    updateRedMark: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    updateRedMarkSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    updateRedMarkFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    resetData: (state) => {
      state.errorsRequest = [];
      state.type = '';
    },
  },
});

const { actions, reducer } = paymentSlice;

export const {
  paymentRequest,
  paymentRequestSuccess,
  paymentRequestFailed,
  resetData,
  paymentBankingRequest,
  paymentRequestMasterCard,
  chillpayRequest,
  paymentStatusRequest,
  PaymentCodeRequest,
  getListPromotion,
  updateRedMark
} = actions;

export default reducer;
