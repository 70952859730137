// @flow
// libs
import React from 'react';
import images from 'themes/images';

export const Loading = () => (
  <div className="loadingLayer">
    <div className="loadingContent">
      <h1 className="loadingMsg">loading now</h1>
      <img
        className="loadingImg"
        src={images.loading.default}
        alt="Loading"
      />
    </div>
  </div>
);

export default Loading;
