/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
function* getDetailPlayer(action) { 
  try { 
    const response = yield call(() =>
      API.get(ROUTES.DETAIL_PLAYER(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'players/detailPlayerRequestSuccess', data });
    } else { 
      yield put({
        type: 'players/detailPlayerRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'players/detailPlayerRequestFailed' });
  }
}

/**
 * Dispatched `getDetailPlayer` action.
 */
function* getDetailPlayerSaga() {
  yield takeLatest('players/detailPlayerRequest', getDetailPlayer);
}

/**
 * worker Saga: will be fired on getPlayerSearch actions
 * 
 * @param {*} action 
 */
 function* getPlayerSearch(action) { 
  try { 
    const response = yield call(() =>
      API.get(ROUTES.GET_SEARCH_PLAYER(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'players/playerSearchRequestSuccess', data });
    } else { 
      yield put({
        type: 'players/playerSearchRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'players/playerSearchRequestFailed' });
  }
}

/**
 * Dispatched `getPlayerSearchSaga` action.
 */
function* getPlayerSearchSaga() {
  yield takeLatest('players/playerSearchRequest', getPlayerSearch);
}

/**
 * worker Saga: will be fired on getPlayerName actions
 * 
 * @param {*} action 
 */
 function* getPlayerName(action) { 
  try { 
    const response = yield call(() =>
      API.get(ROUTES.GET_PLAYER_NAME(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'players/playerNameRequestSuccess', data });
    } else { 
      yield put({
        type: 'players/playerNameRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'players/playerNameRequestFailed' });
  }
}

/**
 * Dispatched `getPlayerName` action.
 */
function* getPlayerNameSaga() {
  yield takeLatest('players/playerNameRequest', getPlayerName);
}

/**
 * worker Saga: will be fired on getPlayerName actions
 * 
 * @param {*} action 
 */
 function* getPlayerInfo(action) { 
  try { console.log(action?.payload, '123');
    const response = yield call(() =>
      API.get(ROUTES.GET_PLAYER_INFO(action?.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'players/playerInfoRequestSuccess', data });
    } else { 
      yield put({
        type: 'players/playerInfoRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'players/playerInfoRequestFailed' });
  }
}

/**
 * Dispatched `getPlayerName` action.
 */
function* getPlayerInfoSaga() {
  yield takeLatest('players/playerInfoRequest', getPlayerInfo);
}

export { getDetailPlayerSaga, getPlayerSearchSaga, getPlayerNameSaga, getPlayerInfoSaga };
