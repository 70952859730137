/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
 function* chargePayment(action) { 
  try { 
      const response = yield call(() =>
        API.post(ROUTES.PAYMENT_CHARGE, action?.payload)
      );
      const { data } = response.data;
      if (response.ok) {
        yield put({ type: 'payments/paymentRequestSuccess', data });
      } else {
        yield put({
          type: 'payments/paymentRequestFailed',
          errors: response?.data?.errors,
        });
      }
  } catch (error) {
    yield put({ type: 'payments/paymentRequestFailed' });
  }
}

/**
 * Dispatched `chargePayment` action.
 */
function* paymentSaveSaga() {
  yield takeLatest('payments/paymentRequest', chargePayment);
}

/**
 * worker Saga: will be fired on getDetailPlayer actions
 * 
 * @param {*} action 
 */
 function* chargeBankingPayment(action) { 
  try { 
      const response = yield call(() =>
        API.post(ROUTES.PAYMENT_CHARGE_BANKING, action?.payload)
      );
      const { data } = response.data;
      if (response.ok) {
        const resuft = yield call(() =>
          API.post(ROUTES.PAYMENT_ACCEPT_BANKING(action?.payload?.user_id), {purchase_item: data})
        );
        const res = resuft.data.data;
        if(res === 'OK') {
          const { payment_method, product_id, myteam_history_id, room_id } = action?.payload;
          const respons = yield call(() =>
            API.post(ROUTES.PAYMENT_CHARGE, {
              payment_method,
              product_id, 
              myteam_history_id,
              room_id,
              button_type: 'paid'
            })
          );
          const resfull = respons.data.data;
          if (respons.ok) {
            yield put({ type: 'payments/paymentBankingRequestSuccess', resfull });
          }
        }
      } else {
        yield put({
          type: 'payments/paymentBankingRequestFailed',
          errors: response?.data?.errors,
        });
      }
  } catch (error) {
    yield put({ type: 'payments/paymentBankingRequestFailed' });
  }
}

/**
 * Dispatched `chargePayment` action.
 */
function* chargeBankingPaymentSaga() {
  yield takeLatest('payments/paymentBankingRequest', chargeBankingPayment);
}

function* paymentMasterCard(action) { 
  try { 
    const response = yield call(() =>
      API.post(ROUTES.PAYMENT_DEBIT, {
        stripeToken: action.payload.stripeToken,
        stripeTokenType: action.payload.stripeTokenType
      })
    );

    if (response.ok) {
      const charge = yield call(() =>
        API.post(ROUTES.PAYMENT_CHARGE, {
            payment_method: 1,
            product_id: 1,
            myteam_history_id: action?.payload?.myteam_history_id,
            fee: action?.payload?.fee,
            room_id: action?.payload?.room_id
          })
      );
      const { data } = charge.data;
      if (charge.ok) {
        yield put({ type: 'payments/paymentRequestMasterCardSuccess', data });
      } else {
        yield put({
          type: 'payments/paymentRequestMasterCardFailed',
          errors: charge?.data?.errors,
        });
      }
    } else { 
      yield put({
        type: 'payments/paymentRequestMasterCardFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'payments/paymentRequestMasterCardFailed' });
  }
}

function* paymentMasterCardSaga() {
  yield takeLatest('payments/paymentRequestMasterCard', paymentMasterCard);
}

function* chillPayPayment(action) { 
  try { 
      const response = yield call(() =>
        API.post(ROUTES.PAYMENT_CHILLPAY, action?.payload)
      );
      const { data } = response.data;
      if (response.ok) {
        yield put({ type: 'payments/chillpayRequestSuccess', data });
      } else {
        yield put({
          type: 'payments/chillpayRequestFailed',
          errors: response?.data?.errors,
        });
      }
  } catch (error) {
    yield put({ type: 'payments/chillpayRequestFailed' });
  }
}

/**
 * Dispatched `chargePayment` action.
 */
function* chillPayPaymentSaga() {
  yield takeLatest('payments/chillpayRequest', chillPayPayment);
}

function* paymentStatus(action) { 
  try { 
      const response = yield call(() =>
        API.get(ROUTES.PAYMENT_CHILLPAY_STATUS(action?.payload))
      );
      const { data } = response.data;
      if (response.ok) {
        yield put({ type: 'payments/paymentStatusRequestSuccess', data });
      } else {
        yield put({
          type: 'payments/paymentStatusRequestFailed',
          errors: response?.data?.errors,
        });
      }
  } catch (error) {
    yield put({ type: 'payments/paymentStatusRequestFailed' });
  }
}

/**
 * Dispatched `chargePayment` action.
 */
function* paymentStatusSaga() {
  yield takeLatest('payments/paymentStatusRequest', paymentStatus);
}

// worker Saga: will be fired on SIGN_UP actions
function* getListPromotion(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.INVITE_CODE_LIST, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'payments/getListPromotionSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'payments/getListPromotionFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'payments/getListPromotionFailed' });
  }
}

/*
  Starts signup Account on each dispatched `signIn` action.
*/
function* getListPromotionSaga() {
  yield takeLatest('payments/getListPromotion', getListPromotion);
}

// worker Saga: will be fired on SIGN_UP actions
function* updateRedMark(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.UPDATE_MARK_READ, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'payments/updateRedMarkSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'payments/updateRedMarkFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'payments/updateRedMarkFailed' });
  }
}

/*
  Starts signup Account on each dispatched `signIn` action.
*/
function* updateRedMarkSaga() {
  yield takeLatest('payments/updateRedMark', updateRedMark);
}

export { 
  paymentSaveSaga,
  chargeBankingPaymentSaga,
  paymentMasterCardSaga,
  chillPayPaymentSaga,
  paymentStatusSaga,
  getListPromotionSaga,
  updateRedMarkSaga
};
