/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

/**
 * worker Saga: will be fired on getNoticeList actions
 *
 * @param {*} action
 */
function* getNoticeList(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.GET_NOTICE_LIST, JSON.stringify(action.payload))
    );

    if (response.ok) {
      const { notice_array } = response.data;

      yield put({ type: 'notices/getNoticeListSuccess', data: notice_array });
    } else {
      yield put({
        type: 'notices/getNoticeListFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'notices/getNoticeListFailed' });
  }
}

/**
 * Dispatched `getNoticeList` action.
 */
function* getNoticeListSaga() {
  yield takeLatest('notices/getNoticeList', getNoticeList);
}

function* settingNotice(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.SETTING_NOTICE, JSON.stringify(action.payload))
    );

    if (response.ok) {
      yield put({ type: 'notices/settingNoticeSuccess' });
    } else {
      yield put({
        type: 'notices/settingNoticeFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'notices/settingNoticeFailed' });
  }
}

/**
 * Dispatched `settingNotice` action.
 */
function* settingNoticeSaga() {
  yield takeLatest('notices/settingNotice', settingNotice);
}

export { getNoticeListSaga, settingNoticeSaga };
