/* eslint-disable camelcase */
// import libs
import { create } from 'apisauce';
import { languageDetectUrl } from 'helpers';

const API_URI = process.env.REACT_APP_API_URL;
const language = languageDetectUrl();

export const ROUTES = {
  // Auths
  SIGN_IN: `/auth/sign_in`,
  RESET_PASSWORD: `/auth/password`,
  SIGN_UP: `/auth`,
  LOGOUT: `/auth/logout`,
  GET_ROMS: (id) => `api/v1/leagues/${id}/rooms`,
  GET_PLAYER_LIST: (romId) => `/api/v1/rooms/${romId}/players`,
  HISTORY_PLAYERS: (id) => `/api/v1/myteam_histories/${id}/myteam_players`,
  ROOM_RANKING: (id) => `api/v1/rooms/${id}/rankings`,
  DETAIL_PLAYER: (idPlayer) => `/api/v1/players/${idPlayer}`,
  GET_SEARCH_PLAYER: (idTab) =>
    `api/v1/leagues/${idTab}/player_search_suggestions`,
  GET_PLAYER_NAME: (idTab) => `api/v1/leagues/${idTab}/player_names`,
  GET_PLAYER_INFO: (idTab) => `api/v1/game_sections/${idTab}/player_info`,
  MYPAGE: `/mypage`,
  GET_WITH_DRAW: `/rewards/withdraw_histories`,
  GET_PRIZE: `/prize_management`,
  GET_BANK_ACCOUNT: `/api/v1/bank_accounts`,
  UPDATE_BANK_ACCOUNT: (idAccount) => `/api/v1/bank_accounts/${idAccount}`,
  SAVE_BANK_ACCOUNT: `/api/v1/bank_accounts`,
  PROMOTION_CODE: `/api/v1/user_promotion_codes`,
  PAYMENT_DEBIT: `/stripe_customers`,
  PAYMENT_CHARGE: `/api/v1/charges`,
  GET_GAME_SESSIONS: (sesionGameId) =>
    `api/v1/game_sections/${sesionGameId}/games`,
  UPDATE_MY_TEAM: (id) => `/api/v1/myteam_players/${id}`,
  GET_MY_TEAM_HISTORY: (room_id) => `/api/v1/rooms/${room_id}/myteam_histories`,
  GET_PRIZES_GAME: (room_id) => `/api/v1/rooms/${room_id}/prizes`,
  GET_ROOM_BY_ID: (id) => `/api/v1/rooms/${id}`,
  GET_CURRENT_USER: `/api/v1/users/current`,
  GET_MY_TEAM: (id) => `api/v1/myteam_histories/${id}`,
  GET_SCORE_PLAYER: (roomId, memberId) =>
    `api/v1/game_sections/${roomId}/player_rankings/${memberId}`,
  GET_LIST_TOP: (id) => `/api/v1/game_sections/${id}/player_rankings`,
  UPDATE_PROFILE: `/profile_update`,
  GET_BANK_INFO: `/api/v1/bank_accounts`,
  RESULT_FOOTBALL: `/api/v1/participating_game_results`,
  USER_RANKING: (roomId, userId) =>
    `/api/v1/rooms/${roomId}/users/${userId}/ranking`,
  REQUEST_AMOUNT_MONEY: `/api/v1/withdraw_moneys`,
  GET_NOTICE_LIST: `/notice_list`,
  SETTING_NOTICE: `/notice/open`,
  GET_EARNED: `/rewards/earned`,
  SAVE_MY_TEAM_HISTORY: (id) => `/api/v1/myteam_histories/${id}/myteam_players/update_list`,
  FACEBOOK_LOGIN: `/auth/facebook/callback`,
  PAYMENT_CHARGE_BANKING: `bank_transfers`,
  PAYMENT_CHILLPAY: `/api/v1/bank_chill_pays`,
  PAYMENT_CHILLPAY_STATUS: (id) => `/chillpay_health_checks/${id}`,
  PAYMENT_ACCEPT_BANKING: (id) => `users/${id}/transfer_requesters`,
  SAVE_ANALYTICS: `/api/v1/redashes`,
  SHARING_FACEBOOK: `/api/v1/shares`,
  UPDATE_LANGUAGE: `/region_lang_update`,
  CONTACT_FORM: `/contacts`,
  CONTACT_DETAIL: (id) => `/contacts/${id}/contact_replies`,
  MAINTAINCE_CHECKED: `/maintaince_checked`,
  INVITE_CODE_LIST: `/invite_code_list`,
  UPDATE_MARK_READ: `/update_mark_read`
};

export const API = create({
  baseURL: API_URI,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': language,
    'token-type': 'Bearer',
  },
});

API.addResponseTransform(response => {
  if ((response.data && Number(response.data.status) === 503) || (response.status >= 500 && response?.config?.url !== '/auth/sign_in') || !response.status) {
    localStorage.setItem('maintance', JSON.stringify(response?.data?.erros || {}));
    if (window.location.pathname !== '/maintenance')
      window.location.href = "/maintenance";
  } else if (window.location.pathname === '/maintenance') {
    localStorage.removeItem('maintance');
    window.location.href = "/";
  }
});
