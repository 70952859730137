/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { renderNoneRoom, updateDataRoom } from 'helpers';

const initialState = {
  userInfo: {},
  isProcessing: false,
  token: '',
  errorsRequest: [],
  payloadSignup: {},
  dataSearchTeam: [],
  chargeBete: [],
  playerList: [],
  myTeamHistories: {},
  dataPlayer: {},
  dataPlayerList: [],
  prizesDetail: [],
  isLoadMore: true,
  isCanNext: false,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateMyTeam: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getRooms: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getRoomsSuccess: (state, action) => {
      const dataPlayerList = [];
      action.data.map((item, key) => {
        if(action.dataPlayerList.length > 0 && action.dataPlayerList[key] && action.dataPlayerList[key].data && action.dataPlayerList[key].data.length > 0) {
          const dataRoom = _.groupBy(action.dataPlayerList[key]?.data, 'position');
          const dataRoomTmp = {
            football_fw: updateDataRoom(dataRoom.football_fw, item.football_fw, 'football_fw'),
            football_mf: updateDataRoom(dataRoom.football_mf, item.football_mf, 'football_mf'),
            football_df: updateDataRoom(dataRoom.football_df, item.football_df, 'football_df'),
            football_gk: updateDataRoom(dataRoom.football_gk, item.football_gk, 'football_gk'),
          }
          dataPlayerList.push(dataRoomTmp);
        } else {
          dataPlayerList.push(renderNoneRoom(item));
        }
      })
      
      state.dataPlayerList = dataPlayerList;
      state.type = action.type;
      state.rooms = action.data;
      state.isProcessing = false;
      state.myTeamHistories = action.myTeamHistories;
      state.isCanNext = action.isCanNext;
    },
    getRoomsFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    getListPlayers: (state, action) => {
      state.type = action.type;
      state.isProcessingPlayer = true;
    },
    getListPlayersSuccess: (state, action) => {
      const dataRes = action.data !== '204' ? action.data : [];
      state.isLoadMore = action.data !== '204';
      state.type = action.type;
      state.rooms = action.data;
      state.isProcessingPlayer = false;
      state.playerList =
        action.currentPage === 0 ? dataRes : [...state.playerList, ...dataRes];
    },
    getListPlayersFailed: (state, action) => {
      state.type = action.type;
      state.isProcessingPlayer = false;
    },

    getSearchTeamSuccess: (state, action) => {
      state.dataSearchTeam = action.data;
    },

    getGameInfo: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getGameInfoSuccess: (state, action) => {
      state.type = action.type;
      state.playerList = action.playerList;
      state.teamIds = action.teamIds;
      state.isProcessing = false;
    },
    getGameInfoFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    resetData: (state, action) => {
      state.type = action.type;
      state.playerList = [];
      state.isLoadMore = true;
    },
    updatePlayerHistory: (state, action) => {
      state.type = action.type;
    },
    updatePlayerHistorySuccess: (state, action) => {
      state.type = action.type;
    },
    updatePlayerHistoryFailed: (state, action) => {
      state.type = action.type;
    },
    addPlayerToHistory: (state, action) => {
      state.type = action.type;
    },
    addPlayerToHistorySuccess: (state, action) => {
      state.type = action.type;
    },
    addPlayerToHistoryFailed: (state, action) => {
      state.type = action.type;
    },
    saveMyTeamHistory: (state, action) => {
      state.isProcessing = true;
      state.type = action.type;
    },
    saveMyTeamHistorySuccess: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },
    saveMyTeamHistoryFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },
    saveMyTeamHistoryFailedMaxPlayer: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },
    getListHistoryPlayer: (state, action) => {
      state.type = action.type;
      state.isProcessing = action.isProcessing;
    },
    getListHistoryPlayerSuccess: (state, action) => {
      state.type = action.type;
      if(action.dataPlayer) {
        const dataRoom = _.groupBy(action?.dataPlayer, 'position');
        const dataRoomTmp = {
          football_fw: updateDataRoom(dataRoom.football_fw, action?.room?.football_fw, 'football_fw'),
          football_mf: updateDataRoom(dataRoom.football_mf, action?.room?.football_mf, 'football_mf'),
          football_df: updateDataRoom(dataRoom.football_df, action?.room?.football_df, 'football_df'),
          football_gk: updateDataRoom(dataRoom.football_gk, action?.room?.football_gk, 'football_gk'),
        }
        state.dataPlayer = dataRoomTmp;
      } else {
        state.dataPlayer = renderNoneRoom(state?.room?.choice_teams);
      }
      state.playerList = action.playerList;
      state.isCanNext = action.isCanNext;
    },
    getListHistoryPlayerFailed: (state, action) => {
      state.type = action.type;
    },
    getPrizesGame: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getPrizesGameSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.prizesDetail = action?.data?.bonus_rates;
    },
    getPrizesGameFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },

    chargeBeta: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    chargeBetaSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    chargeBetaFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },

    saveAnalytics: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    saveAnalyticsSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },
    saveAnalyticsFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },

    getRoomById: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },
    getRoomByIdSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.room = action?.data;
      state.myTeamHistories = action?.myTeamHistoriesRes?.data?.data;
      if(action.dataPlayer) {
        const dataRoom = _.groupBy(action?.dataPlayer, 'position');
        const dataRoomTmp = {
          football_fw: updateDataRoom(dataRoom.football_fw, action?.data?.football_fw, 'football_fw'),
          football_mf: updateDataRoom(dataRoom.football_mf, action?.data?.football_mf, 'football_mf'),
          football_df: updateDataRoom(dataRoom.football_df, action?.data?.football_df, 'football_df'),
          football_gk: updateDataRoom(dataRoom.football_gk, action?.data?.football_gk, 'football_gk'),
        }
        state.dataPlayer = dataRoomTmp;
      } else {
        state.dataPlayer = renderNoneRoom(state?.room?.choice_teams);
      }

      state.isCanNext = action.isCanNext;
    },
    getRoomByIdFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },

    updateMaintainChecked: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    updateMaintainCheckedSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    updateMaintainCheckedFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },

    requestShare: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    shareSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
    },

    shareFailed: (state, action) => {
      state.isProcessing = false;
      state.type = action.type;
    },
  },
});

const { actions, reducer } = homeSlice;

export const {
  updateMyTeam,
  getRooms,
  getRoomsSuccess,
  getRoomsFailed,
  getListPlayersFailed,
  getRoomsFailedSuccess,
  getListPlayers,
  getSearchTeamSuccess,
  getGameInfo,
  resetData,
  updatePlayerHistory,
  addPlayerToHistory,
  getListHistoryPlayer,
  getPrizesGame,
  getRoomById,
  chargeBeta,
  chargeBetaSuccess,
  chargeBetaFailed,
  saveMyTeamHistory,
  saveAnalytics,
  requestShare,
  updateMaintainChecked
} = actions;

export default reducer;
