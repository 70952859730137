
/* eslint-disable prefer-destructuring */
// @flow
import React from 'react';
import images from 'themes/images';
import renderHTML from 'react-render-html';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const Language = () => {
  const t = useTranslation();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const changeLanguage = (lng) => {
    window.localStorage.setItem('language_code', lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  }
  
  const renderFlag = (language) => {
    let image = '';
    switch (language) {
      case 'th':
        image = `<img src="${images.th_flag.default}" alt="th flag" align="middel"/>`
        break;

      case 'ja':
        image = `<img src="${images.jp_flag.default}" alt="th flag" align="middel"/>`
        break;

      default:
        image = `<img src="${images.en_flag.default}" alt="th flag" align="middel"/>`
        break;
    }

    return image;
  }

  return (
    <div className="box-laguage">
      <Dropdown>
        <Dropdown.Toggle variant="success">
          {renderHTML(renderFlag(t.i18n.language))}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => changeLanguage('th')}><img src={images.th_flag.default} alt="th flag" align="middel" />
            {timeZone === 'Asia/Tokyo' ? 'タイ語' : 'ไทย'}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('ja')}><img src={images.jp_flag.default} alt="ja flag" align="middel" />
            {timeZone === 'Asia/Tokyo' ? '日本' : 'ญี่ปุ่น'}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Language;
