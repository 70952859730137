/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { ROUTES, API } from 'api';

// worker Saga: will be fired on SIGN_UP actions
function* signIn(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.SIGN_IN, JSON.stringify(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'accounts/signInRequestSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'accounts/signInRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'accounts/signInRequestFailed' });
  }
}

/*
  Starts signup Account on each dispatched `signIn` action.
*/
function* signInSaga() {
  yield takeLatest('accounts/signInRequest', signIn);
}

// worker Saga: will be fired on SIGN_UP actions
function* signUp(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.SIGN_UP, JSON.stringify(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'accounts/signUpRequestSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'accounts/signUpRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'accounts/signUpRequestFailed' });
  }
}

/*
  Starts signup Account on each dispatched `signUp` action.
*/
function* signUpSaga() {
  yield takeLatest('accounts/signUpRequest', signUp);
}

function* updatePassword(action) {
  try {
    const response = yield call(() =>
      API.put(ROUTES.SIGN_UP, JSON.stringify(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'accounts/updatePasswordSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'accounts/updatePasswordFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'accounts/updatePasswordFailed' });
  }
}

/*
  Starts signup Account on each dispatched `updatePassword` action.
*/
function* updatePasswordSaga() {
  yield takeLatest('accounts/updatePassword', updatePassword);
}
// worker Saga: will be fired on SIGN_UP actions
function* verifyPhoneCode(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.SIGN_IN, JSON.stringify(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: verifyPhoneCode request success
      yield put({ type: 'accounts/verifyPhoneCodeRequestSuccess', data });
    } else {
      // In case: verifyPhoneCode request failed
      yield put({
        type: 'accounts/verifyPhoneCodeRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'accounts/verifyPhoneCodeRequestFailed' });
  }
}

/*
  Starts verifyPhoneCode Account on each dispatched `signUp` action.
*/
function* verifyPhoneCodeSaga() {
  yield takeLatest('accounts/verifyPhoneCodeRequest', verifyPhoneCode);
}

/**
 * worker Saga: will be fired on RESET_PASSWORD actions
 *
 * @param {*} action
 */
function* resetPassword(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.RESET_PASSWORD, JSON.stringify(action.payload))
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'accounts/resetPasswordRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/resetPasswordRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/resetPasswordRequestFailed' });
  }
}

/**
 * Dispatched `resetPassword` action.
 */
function* resetPasswordSaga() {
  yield takeLatest('accounts/resetPasswordRequest', resetPassword);
}

/**
 * worker Saga: will be fired on logout actions
 *
 * @param {*} action
 */
function* logout() {
  try {
    yield put({ type: 'accounts/logoutRequestSuccess' });
  } catch (error) {
    yield put({ type: 'accounts/logoutRequestFailed' });
  }
}

/**
 * Dispatched `logout` action.
 */
function* logoutSaga() {
  yield takeLatest('accounts/logoutRequest', logout);
}

/**
 * worker Saga: will be fired on changePassword actions
 *
 * @param {*} action
 */
function* changePassword(action) {
  try {
    const response = yield call(() =>
      API.put(ROUTES.RESET_PASSWORD, action?.payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'accounts/changePasswordRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/changePasswordRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/changePasswordRequestFailed' });
  }
}

/**
 * Dispatched `changePassword` action.
 */
function* changePasswordSaga() {
  yield takeLatest('accounts/changePasswordRequest', changePassword);
}

/**
 * worker Saga: will be fired on getMypage actions
 *
 * @param {*} action
 */
function* getMypage(action) {
  try {
    const response = yield call(() => API.post(ROUTES.MYPAGE, action?.payload));

    if (response.ok) {
      const { data } = response;

      yield put({ type: 'accounts/mypageRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/mypageRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/mypageRequestFailed' });
  }
}

/**
 * Dispatched `getMypageSaga` action.
 */
function* getMypageSaga() {
  yield takeLatest('accounts/mypageRequest', getMypage);
}

/**
 * worker Saga: will be fired on getWithDraw actions
 *
 * @param {*} action
 */
function* getWithDraw(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_WITH_DRAW, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'accounts/withDrawRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/withDrawRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/withDrawRequestFailed' });
  }
}

/**
 * Dispatched `getWithDrawSaga` action.
 */
function* getWithDrawSaga() {
  yield takeLatest('accounts/withDrawRequest', getWithDraw);
}

/**
 * worker Saga: will be fired on getPrize actions
 *
 * @param {*} action
 */
function* getPrize(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_PRIZE, action?.payload)
    );

    if (response.ok) {
      const { data } = response;

      yield put({ type: 'accounts/prizeRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/prizeRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/prizeRequestFailed' });
  }
}

/**
 * Dispatched `getPrizeSage` action.
 */
function* getPrizeSaga() {
  yield takeLatest('accounts/prizeRequest', getPrize);
}

/**
 * worker Saga: will be fired on getBankAccount actions
 *
 * @param {*} action
 */
function* getBankAccount(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_BANK_ACCOUNT, action?.payload)
    );

    if (response.ok) {
      const { data } = response;

      yield put({ type: 'accounts/getBankAccountRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/getBankAccountRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/getBankAccountRequestFailed' });
  }
}

/**
 * Dispatched `getBankAccountSaga` action.
 */
function* getBankAccountSaga() {
  yield takeLatest('accounts/getBankAccountRequest', getBankAccount);
}

/**
 * worker Saga: will be fired on saveBankAccount actions
 *
 * @param {*} action
 */
/* eslint-disable no-else-return */
function* saveBankAccount(action) {
  try {
    const { institution_name, number, holder, account_id, branch_name, type, user_id } =
      action.payload;
    const response = yield call(() => {
      if (account_id) {
        return API.patch(ROUTES.UPDATE_BANK_ACCOUNT(account_id), {
          institution_name,
          number,
          holder,
          user_id,
          type,
          branch_name,
          account_id
        });
      } else {
        return API.post(ROUTES.SAVE_BANK_ACCOUNT, {
          institution_name,
          number,
          holder,
          user_id,
          type,
          branch_name,
          account_id
        });
      }
    });
    if (response.ok) {
      const { data } = response;

      yield put({ type: 'accounts/saveBankAccountRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/saveBankAccountRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/saveBankAccountRequestFailed' });
  }
}

/**
 * Dispatched `saveBankAccountSaga` action.
 */
function* saveBankAccountSaga() {
  yield takeLatest('accounts/saveBankAccountRequest', saveBankAccount);
}

function* getCurrentUser() {
  try {
    const response = yield call(() => API.get(ROUTES.GET_CURRENT_USER));

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'accounts/getCurrentUserSuccess', data });
    } else {
      yield put({
        type: 'accounts/getCurrentUserFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/getCurrentUserFailed' });
  }
}

/**
 * Dispatched `getCurrentUserSage` action.
 */
function* getCurrentUserSaga() {
  yield takeLatest('accounts/getCurrentUser', getCurrentUser);
}

function* updateProfile(action) {
  try {
    const response = yield call(
      () => API.post(ROUTES.UPDATE_PROFILE, action?.payload),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.ok) {
      if(response.data && Number(response.data.code) === 422) {
        yield put({
          type: 'accounts/updateProfileFailed',
          errors: response?.data?.data,
        });
      } else {
        const { data } = response.data;

        yield put({ type: 'accounts/updateProfileSuccess', data });
      }
    } else {
      yield put({
        type: 'accounts/updateProfileFailed',
        errors: response?.data?.data,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/updateProfileFailed' });
  }
}

/**
 * Dispatched `updateProfileSage` action.
 */
function* updateProfileSaga() {
  yield takeLatest('accounts/updateProfile', updateProfile);
}

/**
 * worker Saga: will be fired on promotionCode actions
 *
 * @param {*} action
 */
function* promotionCode(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.PROMOTION_CODE, action?.payload)
    );

    if (response.ok) {
      const { data } = response;

      yield put({ type: 'accounts/promotionCodeRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/promotionCodeRequestFailed',
        errors: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/promotionCodeRequestFailed' });
  }
}

/**
 * Dispatched `promotionCodeSaga` action.
 */
function* promotionCodeSaga() {
  yield takeLatest('accounts/promotionCodeRequest', promotionCode);
}

/**
 * worker Saga: will be fired on promotionCode actions
 *
 * @param {*} action
 */
function* withDrawMoney(action) {
  try {
    const response = yield call(() =>
      API.post(
        ROUTES.REQUEST_AMOUNT_MONEY,
        JSON.stringify({ amount: action?.payload })
      )
    );

    if (response.ok) {
      const { data } = response;

      yield put({ type: 'accounts/withDrawMoneySuccess', data });
    } else {
      yield put({
        type: 'accounts/withDrawMoneyFailed',
        errors: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/withDrawMoneyFailed' });
  }
}

/**
 * worker Saga: will be fired on promotionCode actions
 *
 * @param {*} action
 */
function* getEarned(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.GET_EARNED, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      yield put({ type: 'accounts/earnedRequestSuccess', data });
    } else {
      yield put({
        type: 'accounts/earnedRequestFailed',
        errors: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: 'accounts/earnedRequestFailed' });
  }
}

/**
 * Dispatched `promotionCodeSaga` action.
 */
function* getEarnedSaga() {
  yield takeLatest('accounts/earnedRequest', getEarned);
}

/**
 * Dispatched `withDrawMoneySaga` action.
 */
function* withDrawMoneySaga() {
  yield takeLatest('accounts/withDrawMoney', withDrawMoney);
}

// worker Saga: will be fired on SIGN_UP actions
function* loginFacebook(action) {
  try {
    const response = yield call(() =>
      API.get(ROUTES.FACEBOOK_LOGIN, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'accounts/loginFacebookRequestSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'accounts/loginFacebookRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'accounts/loginFacebookRequestFailed' });
  }
}

/*
  Starts signup Account on each dispatched `signIn` action.
*/
function* loginFacebookSaga() {
  yield takeLatest('accounts/loginFacebookRequest', loginFacebook);
}

// worker Saga: will be fired on SIGN_UP actions
function* changeLanguage(action) {
  try {
    const response = yield call(() =>
      API.post(ROUTES.UPDATE_LANGUAGE, action?.payload)
    );

    if (response.ok) {
      const { data } = response.data;

      // In case: signup request success
      yield put({ type: 'accounts/changeLanguageRequestSuccess', data });
    } else {
      // In case: signup request failed
      yield put({
        type: 'accounts/changeLanguageRequestFailed',
        errors: response?.data?.errors,
      });
    }
  } catch (error) {
    // in case: server error
    yield put({ type: 'accounts/changeLanguageRequestFailed' });
  }
}

/*
  Starts signup Account on each dispatched `signIn` action.
*/
function* changeLanguageSaga() {
  yield takeLatest('accounts/changeLanguageRequest', changeLanguage);
}

export {
  signUpSaga,
  signInSaga,
  verifyPhoneCodeSaga,
  resetPasswordSaga,
  logoutSaga,
  changePasswordSaga,
  getMypageSaga,
  getWithDrawSaga,
  getPrizeSaga,
  getCurrentUserSaga,
  updateProfileSaga,
  updatePasswordSaga,
  getBankAccountSaga,
  saveBankAccountSaga,
  promotionCodeSaga,
  withDrawMoneySaga,
  getEarnedSaga,
  loginFacebookSaga,
  changeLanguageSaga
};
