import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playerInfo: {},
  isProcessing: false,
  errorsRequest: '',
  rankingTeam: [],
  rankingRoom: [],
  rankingTop: [],
  myTeam: [],
  resultFootball: [],
  playerScore: {},
  listPointScore: [],
  userRanking: {},
};

const rankingSlice = createSlice({
  name: 'rankings',
  initialState,
  reducers: {
    rankingTeamRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    rankingTeamRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.rankingTeam = action.data;
    },

    rankingTeamRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    rankingRoomRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    rankingRoomRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.rankingRoom = action?.data?.map((item) => ({
        ...item,
        id: Math.random(),
      }));
    },

    rankingRoomRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    getResultFootball: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getResultFootballSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.resultFootball = action.data;
    },

    getResultFootballFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    myTeamRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    myTeamRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.myTeam = action.data;
    },

    myTeamRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    getPlayerScoreRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getPlayerScoreRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.listPointScore = action.data;
    },

    getPlayerScoreRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    playerScore: (state, action) => {
      state.playerScore = action.payload;
    },

    rankingDetailRequest: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    rankingDetailRequestSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.rankingTop = action.data.data;
    },

    rankingDetailRequestFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.errorsRequest = action.errors;
    },

    getRankingByUserId: (state, action) => {
      state.type = action.type;
      state.isProcessing = true;
    },

    getRankingByUserIdSuccess: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.userRanking = action.data;
    },

    getRankingByUserIdFailed: (state, action) => {
      state.type = action.type;
      state.isProcessing = false;
      state.userRanking = {};
      state.errorsRequest = action.errors;
    },

    resetData: (state) => {
      state.errorsRequest = [];
      state.type = '';
    },
  },
});

const { actions, reducer } = rankingSlice;

export const {
  rankingTeamRequest,
  rankingTeamRequestSuccess,
  rankingTeamRequestFailed,
  rankingRoomRequest,
  rankingRoomRequestSuccess,
  rankingRoomRequestFailed,
  getResultFootball,
  myTeamRequest,
  myTeamRequestSuccess,
  myTeamRequestFailed,
  getPlayerScoreRequest,
  getPlayerScoreRequestSuccess,
  getPlayerScoreRequestFailed,
  rankingDetailRequest,
  rankingDetailRequestSuccess,
  rankingDetailRequestFailed,
  getRankingByUserId,
  playerScore,
  resetData,
} = actions;

export default reducer;
